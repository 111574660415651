import React, { useContext, useEffect } from 'react'
import MuiPhoneInput from 'material-ui-phone-number'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import { InputAdornment, IconButton } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

const CustomPhone = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const { allConfigurationVariables } = customCommonContext

    const shrink = props.shrink ? props.shrink.toString() : 'false'

    const onFocus = (event) => {
        console.log(event, 'evnn')
        if (props.disableAutocomplete) {
            event.target.removeAttribute('readonly')
        }
        if (props.onFocus) {
            props.onFocus(event)
        }
    }

    const handlePhoneNumberChange = (value, allDetails) => {
        console.log(value, 'valkkk')
        if (props.onError) {
            const divElement = document.querySelector(
                `.MuiInputBase-root.Mui-error input[name="${props.name}"]`,
            )

            // Check if the "Mui-error" class is present
            let isError = false
            if (divElement) {
                console.log(divElement.parentNode, 'divElement.parentNode')
                if (divElement.parentNode.classList.contains('Mui-error')) {
                    if (props.displayFlag) {
                        let trimmed = value.replace(/[ ()-]/g, '')
                        console.log(trimmed, 'trimmed')
                        let substract = trimmed.substring(allDetails.dialCode.length + 1)
                        if (substract.length !== 10) {
                            isError = true
                        } else {
                            isError = false
                        }
                    } else if (props.minChar && props.maxChar) {
                        if (value.length > props.minChar && value.length < props.maxChar) {
                            isError = true
                        } else {
                            isError = false
                        }
                    }
                } else {
                    isError = false
                }
            }

            if (isError) {
                // Handle the error, e.g., log it

                props.onError(true)

                console.log('Error handling phone number')
            } else {
                // Your logic when there is no error
                if (props.displayFlag) {
                    let trimmed = value.replace(/[ ()-]/g, '')
                    console.log(trimmed, 'trimmed')
                    let substract = trimmed.substring(allDetails.dialCode.length + 1)
                    console.log(substract, 'substract123')
                    if (substract.length !== 10) {
                        props.onError(true)
                    } else {
                        props.onError(false)
                    }
                    props.onChange(value)
                } else {
                    props.onError(false)
                    props.onChange(value)
                }
            }
        } else {
            props.onChange(value)
        }
    }

    useEffect(() => {
        console.log(props.value, 'propsvalue')
    }, [props.value])

    const inputProps = props.disableAutocomplete
        ? { ...props.inputProps, autoComplete: 'new-password', readOnly: true }
        : props.inputProps

    return (
        <div className="customInput">
            {props.displayFlag ? (
                <>
                    <>
                        {props?.upperLabel ? <label>{props.label}</label> : null}
                        <MuiPhoneInput
                            value={
                                props.value
                                    ? props.value
                                    : allConfigurationVariables &&
                                      allConfigurationVariables.country_code
                                    ? allConfigurationVariables.country_code
                                    : '+1'
                            }
                            autoFocus={props.autoFocus}
                            enableLongNumbers={true}
                            name={props.name}
                            onChange={handlePhoneNumberChange}
                            onFocus={onFocus}
                            disableAreaCodes={true}
                            id={props.id}
                            label={props?.upperLabel ? null : props.label}
                            type={props.type}
                            size={props.size}
                            disabled={props.disabled}
                            variant={props.variant ? props.variant : 'outlined'}
                            placeholder={props.placeholder}
                            error={props.error}
                            helperText={props.helperText}
                            disableDropdown={props.disabled}
                        />
                    </>
                </>
            ) : props.upperLabel ? (
                <>
                    <label>{props.label}</label>
                    <MuiPhoneInput
                        value={
                            props.value
                                ? props.value
                                : allConfigurationVariables &&
                                  allConfigurationVariables.country_code
                                ? allConfigurationVariables.country_code
                                : '+1'
                        }
                        autoFocus={props.autoFocus}
                        name={props.name}
                        // onChange={props.onChange}
                        onChange={handlePhoneNumberChange}
                        onFocus={onFocus}
                        InputProps={{
                            inputProps: inputProps,
                            startAdornment: props.startAdornment,
                            shrink: shrink,
                        }}
                        id={props.id}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                        disableCountryCode
                        countryCodeEditable={props.countryCodeEditable}
                    />
                </>
            ) : (
                <MuiPhoneInput
                    value={
                        props.value
                            ? props.value
                            : allConfigurationVariables && allConfigurationVariables.country_code
                            ? allConfigurationVariables.country_code
                            : '+1'
                    }
                    autoFocus={props.autoFocus}
                    name={props.name}
                    onChange={handlePhoneNumberChange}
                    onFocus={onFocus}
                    InputProps={{
                        inputProps: inputProps,
                        startAdornment: props.startAdornment,
                        endAdornment:
                            props.endAdornment === 'info' ? (
                                <InputAdornment position="end">
                                    <Tooltip title={props.tooltiptitle}>
                                        <IconButton>
                                            <span className="material-icons">info</span>
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ) : (
                                props.endAdornment
                            ),
                        shrink: shrink,
                    }}
                    id={props.id}
                    label={props.label}
                    type={props.type}
                    size={props.size}
                    disabled={props.disabled}
                    variant={props.variant ? props.variant : 'outlined'}
                    placeholder={props.placeholder}
                    error={props.error}
                    helperText={props.helperText}
                    countryCodeEditable={props.countryCodeEditable}
                />
            )}
        </div>
    )
}

export default CustomPhone
