import React, { useState, useContext, useEffect } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button, Menu, MenuItem, Popover, Tooltip } from '@material-ui/core'
// import CustomInput from '../../atoms/Inputs/CustomInput'
// import PrimaryButton from '../../atoms/PrimaryButton'
import { withStyles } from '@material-ui/core/styles'
import CustomDialog from '../Dialog'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import Rating from '@material-ui/lab/Rating'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './ProductView.css'
import Buynow from '../../molecules/Buynow/BuynowItem'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import FavouriteCheckbox from '../../../../product/components/atoms/FavoriteCheckbox'
import { mapData } from '../../../../product/common/components'
import { dateFormatFront, handleRedirectInternal, checkQuantity } from '../../../common/components'
// import TertiaryButton from '../../atoms/TertiaryButton'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import Timer from '../../../../product/common/timer'
import Bidding from '../../molecules/Bidding/BiddingItem'
import ProductContext from '../../../../product/context/product/productContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Loaders from '../../molecules/Loaders'
import { currencyFormat } from '../../../../custom/common/components'
import ReadMore from '../../../../product/components/atoms/ReadMore'
import { serverTime } from '../../../../product/common/socket'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
const ProductView = (props) => {
    const customCommonContext = useContext(CustomCommonContext)

    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    const [product, setProduct] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [toggleDialog, setToggleDialog] = useState(false)
    const [rating, setRating] = useState(5)
    const [followerList, setFollowerList] = useState([])
    const [buyNowEnabled, setBuyNowEnabled] = useState(true)
    const history = useHistory()

    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const productCommonContext = useContext(ProductCommonContext)
    const [currentTimer, setCurrentTimer] = useState(false)
    const [showBidding, setShowBidding] = useState(false)

    const { isAuthenticated } = authContext
    const {
        search_allsimilar,
        getAllSimilarProducts,
        addFollower,
        removeFollower,
        followers,
        getFollowers,
    } = productContext
    const {
        allConfigurationVariables,
        wineColorOptions,
        wineLocation,
        wineBottleSizeOptions,
        configVariables,
    } = customCommonContext
    const { setBidHistoryValue } = productCommonContext

    useEffect(() => {
        setProduct(props.data)
        setTimeout(() => {
            setIsLoading(false)
        }, 2500)
    }, [props.data])

    useEffect(() => {
        if (isAuthenticated) {
            getFollowers()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (followers) {
            setFollowerList(followers.records)
        }
    }, [followers])

    useEffect(() => {
        setIsLoading(props.loading && true)

        if (product) {
            const search = {
                limit: 3,
                page: 1,
                orderby: 'p.date_closed,asc',
                order: '',
                similars: {
                    category: {
                        value: [product.categoryTypeId],
                        type: 'array',
                        field: 'it.categoryTypeId',
                    },
                    condition: {
                        value: [product.conditionTypeId],
                        type: 'array',
                        field: 'it.conditionTypeId',
                    },
                    location: {
                        value: [product.location_id],
                        type: 'array',
                        field: 'p.location_id',
                    },
                    auctionid: {
                        value: [product.auctionid],
                        type: 'array',
                        field: 'p.auctionid',
                    },
                },
                filters: {
                    product_id: {
                        value: product.id,
                        type: 'notin',
                        field: 'p.id',
                    },
                },
            }
            getAllSimilarProducts(search)
        }
    }, [product ? product.id : 0])

    useEffect(() => {
        // setIsLoading(false)
        setViewProduct(search_allsimilar.records.length ? search_allsimilar.records : [])
    }, [search_allsimilar])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }

    useEffect(() => {
        let noImageArray = [
            {
                original: '/assets/images/noimage.png',
                thumbnail: '/assets/images/noimage.png',
            },
        ]
        if (product) {
            if (product.allattachmentlist) {
                if (product.allattachmentlist.length) {
                    product.allattachmentlist.forEach(function (data) {
                        if (data.type === 2) {
                            // data['original'] = data.file_name
                            // data['thumbnail'] = data.file_name
                            data['original'] = data?.file_name?.replace('www.', 'staging.')
                            data['thumbnail'] = data?.file_name?.replace('www.', 'staging.')

                            console.log('checkHere 1', data, data.file_name)
                        } else {
                            data['original'] = global.images_url + data['file_name']
                            data['thumbnail'] = global.images_url + data['file_name']
                            console.log('checkHere 2', data, global.images_url + data['file_name'])
                        }
                    })
                    setImages(product.allattachmentlist)
                    // console.log('images: ', images)
                } else {
                    setImages(noImageArray)
                }
            } else {
                setImages(noImageArray)
            }
        } else {
            setImages(noImageArray)
        }
    }, [product])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const onFollow = () => {
        const follows = product.custitem_gvo_producer
        addFollower({ follows: follows, rating: rating })
        changeDialogStatus()
        setTimeout(() => {
            getFollowers()
        }, 500)
    }

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const onTimerAlert = () => {
        setBuyNowEnabled(false)
        setCurrentTimer(true)
    }

    const formik = useFormik({
        initialValues: {
            discount: [],
        },
        onSubmit: (values, actions) => {},
    })

    const discountSelect = {
        formik: formik,
        data: [
            {
                label: 'Discount Drop down',
                placeholder: 'Select' + ' ' + 'Discount Drop down',
                class: 'col-12 col-sm-6',
                type: 'select',
                options:
                    product &&
                    product.discount_buynow_records?.map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = `${buscat.quantity}`
                        busCategoryChanged.value = buscat.id
                        return busCategoryChanged
                    }),
                name: 'discount',
            },
        ],
    }

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const getEndRange = (data, index) => {
        let endRange = ''
        if (index + 1 < data.length) {
            endRange = ` - ${data[index + 1]?.quantity - 1}`
        } else endRange = ' & up'
        return endRange
    }

    const [dropValue, setDropValue] = useState({
        custitem_gvo_bottlesize: '',
    })

    useEffect(() => {
        if (product) {
            let bottleSizeText = ''
            let filteredValue = wineBottleSizeOptions.filter((main) => {
                if (main.id === parseInt(product.custitem_gvo_bottlesize, 10)) {
                    return true
                }
            })
            if (filteredValue.length) {
                bottleSizeText = filteredValue[0].description
            }
            setDropValue({ ...dropValue, custitem_gvo_bottlesize: bottleSizeText })
        }
    }, [product, wineBottleSizeOptions])

    const buynowCondition = (product) => {
        if (product.market_status === 'open' && !product.future_active) {
            if (product.auction === 1) {
                if (product.buynow === 1) {
                    if (product.wprice / product.qty < product.bprice + product.bprice * 0.2) {
                        let startDate = new Date(serverTime)
                        let endDate = new Date(product.auctionlot_date_closed)
                        let timerTime = endDate.getTime() - startDate.getTime()
                        let days = Math.floor(timerTime / (1000 * 60 * 60 * 24))
                        if (days < 2) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } else if (product.buynow === 1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <div
            className={`pdtViewWrpr ${props.standalone && 'customContainer standalonePage'}`}
            id="productView"
        >
            <span className="d-none">
                <Timer
                    date_added={product?.date_added}
                    date_closed={product?.date_closed}
                    withText={1}
                    endText="Time Left: "
                    startText="Starts in: "
                    alertListener={onTimerAlert}
                ></Timer>
            </span>
            {isLoading ? (
                <div className="noGridLoader">
                    <Loaders name="product_view" isLoading={isLoading} loop={1} />
                </div>
            ) : product ? (
                <>
                    <div className="pdtViewWrpr customContainer py-5">
                        {!props.standalone && (
                            <>
                                <Button className="closeDrawer" onClick={props.onClick}>
                                    <span className="material-icons">close</span>
                                </Button>
                                <Button
                                    className="lotPreview"
                                    onClick={() =>
                                        handleRedirectInternal(history, `productView/${product.id}`)
                                    }
                                >
                                    View Full Details
                                    <span className="material-icons-outlined">open_in_new</span>
                                </Button>
                            </>
                        )}
                        <div className="row mb-3">
                            <Button
                                className="backButton"
                                onClick={() => history.goBack()}
                                id="back_button"
                            >
                                <span className="material-icons mr-2">keyboard_backspace</span>Back
                            </Button>
                        </div>
                        <div className="d-md-none d-block d-lg-none text-left">
                            <h1 className="pdtTtile text-left mt-0">{product.title}</h1>
                            <div className="d-flex align-items-center pvSku">
                                <small className="pdtSku">SKU: {product.itemId}</small>
                                {product.auction === 1 ? (
                                    <>
                                        <span></span>
                                        <small className="pdtSku">
                                            Lot # {product.custitemlot_number || product.internalId}
                                        </small>
                                    </>
                                ) : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 pro-view-left">
                                <div className="pdtImgContainer">
                                    <ImageGallery
                                        items={images}
                                        thumbnailPosition="bottom"
                                        showNav={false}
                                        showBullets={false}
                                        showFullscreenButton={true}
                                        showPlayButton={false}
                                        onImageError={addDefaultSrc}
                                        onThumbnailError={addDefaultSrc}
                                    />
                                </div>

                                {product.custitem_gvo_color && (
                                    <div className="d-flex flex-wrap justify-content-center align-items-end mt-sm-4 mb-3">
                                        <img
                                            src={`/assets/svg/wine_bottle_colors/badge_${
                                                wineColorOptions
                                                    .filter(
                                                        (item) =>
                                                            item.id == product.custitem_gvo_color,
                                                    )
                                                    .map((d) => d.description)[0]
                                            }.svg`}
                                        />
                                        <div className="d-flex flex-wrap align-items-center mt-2 mt-sm-0 ml-sm-2">
                                            <p className="m-0">{product.custitemctregion}</p>
                                            {product.custitemctsubregion ? (
                                                <>
                                                    <img
                                                        src="/assets/svg/dot.svg"
                                                        className="mx-2"
                                                    />
                                                    <p className="m-0">
                                                        {product.custitemctsubregion}
                                                    </p>
                                                </>
                                            ) : null}
                                            {dropValue.custitem_gvo_bottlesize ? (
                                                <>
                                                    <img
                                                        src="/assets/svg/dot.svg"
                                                        className="mx-2"
                                                    />
                                                    <p className="m-0">
                                                        {dropValue.custitem_gvo_bottlesize}
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                )}
                                <div className="d-md-flex d-lg-flex d-none flex-wrap align-items-center justify-content-center mt-4">
                                    {product.market_status === 'open' && (
                                        <FavouriteCheckbox
                                            watchlisted={product.watchlistid}
                                            project_id={product.id}
                                            addLabel="Add to Watchlist"
                                            removeLabel="Remove from Watchlist"
                                            className="pvFavorites"
                                            follows={
                                                product.custitem_gvo_producer
                                                    ? product.custitem_gvo_producer
                                                    : ''
                                            }
                                        />
                                    )}
                                </div>
                                {/* <div className="adtnlTxtInfo">
                                    <div className="tgLn mt-4 text-left">
                                        <h5>Timed Auction</h5>
                                        <p>{product.title}</p>
                                    </div>
                                    <div className="tgLn mt-4 text-left">
                                        <h5>Producer</h5>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p>{product.custitem_gvo_producer}</p>
                                            {followerList.filter(
                                                (f) => f.follows === product.custitem_gvo_producer,
                                            ).length > 0 ? (
                                                <Rating
                                                    name="rated"
                                                    value={
                                                        followerList.filter(
                                                            (f) =>
                                                                f.follows ===
                                                                product.custitem_gvo_producer,
                                                        )[0].rating
                                                    }
                                                    onChange={(event, newValue) => {
                                                        setRating(newValue)
                                                    }}
                                                    readOnly
                                                />
                                            ) : (
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setToggleDialog(true)}
                                                >
                                                    Follow
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    <div className="tgLn mt-4 text-left">
                                        <h5>Category</h5>
                                        <p>{product.location_id}</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-lg-8 text-left">
                                {isAuthenticated && (
                                    <>
                                        {product.bidtopstatus === 'outbid' && (
                                            <div className="pvBidStatus">
                                                <h4 className="outbid">
                                                    {'you have been outbid'}.
                                                </h4>
                                            </div>
                                        )}
                                        {product.bidtopstatus === 'winner' && (
                                            <div className="pvBidStatus">
                                                <h4 className="winning">
                                                    {'You are winning this lot'}.
                                                </h4>
                                            </div>
                                        )}
                                        {product.bidtopstatus === 'won' && (
                                            <div className="pvBidStatus">
                                                <h4 className="won">
                                                    {'Congratulations you have won this item'}
                                                </h4>
                                            </div>
                                        )}
                                        {product.bidtopstatus === 'lost' && (
                                            <div className="pvBidStatus">
                                                <h4 className="lost">
                                                    {'Unfortunately you have lost this item'}
                                                </h4>
                                            </div>
                                        )}
                                    </>
                                )}
                                <h1 className="pdtTtile text-left mt-0 d-none d-md-block d-lg-block">
                                    {product.title}
                                </h1>
                                <div className="d-flex align-items-center pvSku">
                                    <small className="pdtSku d-none d-md-block d-lg-block">
                                        SKU: {product.itemId}
                                    </small>
                                    {product.auction === 1 ? (
                                        <>
                                            <span></span>
                                            <small className="pdtSku d-none d-md-block d-lg-block">
                                                Lot #{' '}
                                                {product.custitemlot_number || product.internalId}
                                            </small>
                                        </>
                                    ) : null}
                                </div>

                                {/* <div className="mt-2 mb-2 mb-sm-3 pvCond">
                                    <p>
                                        <span>Condition: SLC, Damaged Capsule</span>
                                    </p>
                                </div> */}
                                {/* <div className="row prcngInfoVw">
                                    <div className="col-md-12 mb-sm-3">
                                        <h2>
                                            {currencyFormat(product.bprice)} <small>each</small>
                                        </h2>
                                    </div>
                                </div> */}

                                {/* ************** */}
                                <div className="bdngCntnr w-100">
                                    <div className="d-flex align-items-center justify-content-between dibHisty">
                                        <div className="boxContainer exChgPos">
                                            {product.custitem_gvo_ws ? (
                                                <div className={`box_one WS`}>
                                                    <div className="topContainer">WS</div>
                                                    <div className="btmContainer">
                                                        {product.custitem_gvo_ws}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitem_gvo_ratingtextbhscore ? (
                                                <div className={`box_one BH`}>
                                                    <div className="topContainer">BH</div>
                                                    <div className="btmContainer">
                                                        {product.custitem_gvo_ratingtextbhscore}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitem_gvo_ratingtextiwcscore ? (
                                                <div className={`box_one IWC`}>
                                                    <div className="topContainer">IWC</div>
                                                    <div className="btmContainer">
                                                        {product.custitem_gvo_ratingtextiwcscore}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitem_gvo_ratingtextjsscore ? (
                                                <div className={`box_one JS`}>
                                                    <div className="topContainer">JS</div>
                                                    <div className="btmContainer">
                                                        {product.custitem_gvo_ratingtextjsscore}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitem_gvo_ratingtextvmrating ? (
                                                <div className={`box_one VM`}>
                                                    <div className="topContainer">VM</div>
                                                    <div className="btmContainer">
                                                        {product.custitem_gvo_ratingtextvmrating}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitem_gvo_wa ? (
                                                <div className={`box_one WA`}>
                                                    <div className="topContainer">WA</div>
                                                    <div className="btmContainer">
                                                        {product.custitem_gvo_wa}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitem2 ? (
                                                <div className={`box_one JD`}>
                                                    <div className="topContainer">JD</div>
                                                    <div className="btmContainer">
                                                        {product.custitem2}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitem3 ? (
                                                <div className={`box_one JR`}>
                                                    <div className="topContainer">JR</div>
                                                    <div className="btmContainer">
                                                        {product.custitem3}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitemdm ? (
                                                <div className={`box_one DM`}>
                                                    <div className="topContainer">DM</div>
                                                    <div className="btmContainer">
                                                        {product.custitemdm}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {product.custitemtwi ? (
                                                <div className={`box_one TWI`}>
                                                    <div className="topContainer">TWI</div>
                                                    <div className="btmContainer">
                                                        {product.custitemtwi}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="m-0 startBid">
                                                {/* Starting Bid at {currencyFormat(product.sprice)} each */}
                                            </p>
                                            {product.auction ? (
                                                <Button
                                                    id="viewbidhistory_button"
                                                    className="pvBidHistBtn"
                                                    onClick={() => setBidHistoryValue(product.id)}
                                                >
                                                    <span className="material-icons">history</span>{' '}
                                                    View Bid History
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    {product.market_status === 'open' ? (
                                        <div
                                            className={`bdIptWpr ${
                                                !buynowCondition(product) ||
                                                (!product.buynow && product.auction) ||
                                                (product.buynow && !product.auction)
                                                    ? 'limitWidth'
                                                    : ''
                                            }`}
                                        >
                                            <div
                                                className={`row ${
                                                    currentTimer ? 'justify-content-end' : ''
                                                }`}
                                            >
                                                {buynowCondition(product) && (
                                                    <>
                                                        <div
                                                            className={`${
                                                                product.auction
                                                                    ? 'col-md-5'
                                                                    : 'col-md-12'
                                                            }`}
                                                        >
                                                            <div className="d-flex align-items-center justify-content-between w-100 mb-2 mb-sm-4">
                                                                <h3 className="mb-0 priceTitle">
                                                                    Buy Now Price:{' '}
                                                                    <span className="colorPrimary">
                                                                        {currencyFormat(
                                                                            product.bprice,
                                                                        )}{' '}
                                                                        each{' '}
                                                                    </span>
                                                                </h3>
                                                            </div>
                                                            <label>
                                                                Available:{' '}
                                                                <span className={`ml-2`}>
                                                                    {checkQuantity(
                                                                        product.custitem_gvo_invwebdisplay,
                                                                        product.qty,
                                                                        product.sold,
                                                                    )}
                                                                </span>
                                                                {/* <span
                                                                    className={`ml-2 ${
                                                                        product.qty <= 2 &&
                                                                        'emptySoon'
                                                                    }`}
                                                                >{`${
                                                                    product.qty <= 2
                                                                        ? ` Only ${product.qty} left in stock`
                                                                        : product.qty
                                                                } `}</span> */}
                                                            </label>
                                                            {
                                                                <div className="bdPlcWrpr">
                                                                    {/* <div className="pvActBtn">
                                                                        <div className="pabChild pvCurrentBid d-flex align-items-center">
                                                                            <label>
                                                                                Buy now price
                                                                            </label>
                                                                            <h4>
                                                                                {currencyFormat(
                                                                                    product.bprice,
                                                                                )}
                                                                            </h4>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="pvBidBox">
                                                                        {product.market_status ===
                                                                            'open' &&
                                                                        !product.future_active ? (
                                                                            <>
                                                                                {product.buynow && (
                                                                                    <Buynow
                                                                                        data={
                                                                                            product
                                                                                        }
                                                                                        editQuantity={
                                                                                            true
                                                                                        }
                                                                                        isOnlyBuynow={
                                                                                            product.auction ===
                                                                                            0
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {/* {mapData(discountSelect)} */}

                                                            {product.discount_buynow_records
                                                                ?.length > 0 && (
                                                                <Button
                                                                    aria-controls="simple-menu"
                                                                    aria-haspopup="true"
                                                                    className="qtyAvbl"
                                                                    onClick={handleClick}
                                                                    id="quamtitydiscounts_button"
                                                                >
                                                                    Quantity Discounts Available
                                                                    <span className="material-icons">
                                                                        keyboard_arrow_down
                                                                    </span>
                                                                </Button>
                                                            )}

                                                            <Popover
                                                                id={'simple-menu'}
                                                                open={Boolean(anchorEl)}
                                                                className="qtyPopover"
                                                                anchorEl={anchorEl}
                                                                onClose={handleClose}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                            >
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">
                                                                                Quantity
                                                                            </th>
                                                                            <th scope="col">
                                                                                Price
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {product.discount_buynow_records?.map(
                                                                            (data, index) => (
                                                                                <tr
                                                                                    key={`discount_${index}`}
                                                                                >
                                                                                    <th scope="row">
                                                                                        {index == 0
                                                                                            ? 1
                                                                                            : product
                                                                                                  ?.discount_buynow_records[
                                                                                                  index
                                                                                              ]
                                                                                                  ?.quantity}
                                                                                        {getEndRange(
                                                                                            product?.discount_buynow_records,
                                                                                            index,
                                                                                        )}
                                                                                    </th>
                                                                                    <td>
                                                                                        {currencyFormat(
                                                                                            data.bprice,
                                                                                        )}{' '}
                                                                                        each
                                                                                    </td>
                                                                                </tr>
                                                                            ),
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </Popover>
                                                        </div>
                                                        {product.auction ? (
                                                            <div className="col-md-2 text-center orBtnStn">
                                                                OR
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                                {product.auction ? (
                                                    <div
                                                        className={`${
                                                            buynowCondition(product)
                                                                ? 'col'
                                                                : 'col-md-12'
                                                        }`}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-between w-100 mb-4">
                                                            <h3 className="mb-0 priceTitle">
                                                                Current Bid:{' '}
                                                                {currencyFormat(product.wprice)} per
                                                                lot
                                                            </h3>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <label>
                                                                Lot Size:{' '}
                                                                {checkQuantity(
                                                                    product.custitem_gvo_invwebdisplay,
                                                                    product.qty,
                                                                    product.sold,
                                                                )}
                                                                x{' '}
                                                                {dropValue.custitem_gvo_bottlesize}
                                                            </label>
                                                            {/* <small className="d-flex align-items-center">
                                                        <span className="material-icons mr-2">
                                                            lock
                                                        </span>
                                                        Secure
                                                    </small> */}
                                                        </div>
                                                        <div className="pvBidBox">
                                                            {product.market_status === 'open' &&
                                                            !product.future_active ? (
                                                                <>
                                                                    {product.auction ? (
                                                                        <Bidding
                                                                            data={product}
                                                                            type={
                                                                                parseInt(
                                                                                    configVariables?.proxy_bidding_settings,
                                                                                ) === 0
                                                                                    ? 'hard'
                                                                                    : 'proxy'
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </>
                                                            ) : null}
                                                        </div>
                                                        <p className="m-0 d-flex align-items-center timerCon justify-content-center mt-4">
                                                            {/* {console.log(
                                                                'currentTimer',
                                                                currentTimer,
                                                            )} */}
                                                            {product.market_status === 'open' ? (
                                                                <>
                                                                    <div
                                                                        className={`pvTimerBox ${
                                                                            currentTimer
                                                                                ? ''
                                                                                : 'd-none'
                                                                        }`}
                                                                    >
                                                                        <h4 className="d-flex align-items-center">
                                                                            <span className="material-icons-outlined">
                                                                                timer
                                                                            </span>
                                                                            <Timer
                                                                                date_added={
                                                                                    product.date_added
                                                                                }
                                                                                date_closed={
                                                                                    product.date_closed
                                                                                }
                                                                                withText={1}
                                                                                endText="Time Left: "
                                                                                startText="Starts in: "
                                                                                // alertListener={
                                                                                //     onTimerAlert
                                                                                // }
                                                                            ></Timer>
                                                                        </h4>
                                                                    </div>
                                                                    <div
                                                                        className={`pvTimerBox ${
                                                                            currentTimer
                                                                                ? 'd-none'
                                                                                : 'd-flex align-items-center'
                                                                        }`}
                                                                    >
                                                                        <span className="material-icons-outlined mr-2">
                                                                            timer
                                                                        </span>
                                                                        <label className="mb-0 mr-2">
                                                                            Auction Ends:
                                                                        </label>
                                                                        <h4 className="mb-0 mt-0">
                                                                            {dateFormatFront(
                                                                                product.date_closed,
                                                                            )}
                                                                        </h4>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="pvTimerBox">
                                                                    <span className="material-icons-outlined">
                                                                        timer
                                                                    </span>
                                                                    <label>{'Closed On'}</label>
                                                                    <h4>
                                                                        {dateFormatFront(
                                                                            product.date_closed,
                                                                        )}
                                                                    </h4>
                                                                </div>
                                                            )}
                                                        </p>
                                                        <div
                                                            className={`pvTimerBox ${
                                                                currentTimer
                                                                    ? 'd-none'
                                                                    : 'd-flex align-items-center'
                                                            }`}
                                                        >
                                                            <label className="mt-2 d-flex align-items-center ">
                                                                Bids do not include 20% Buyer&apos;s
                                                                Premium
                                                                <Tooltip
                                                                    title={`${allConfigurationVariables.premium_explanation}`}
                                                                    arrow
                                                                >
                                                                    <i className="material-icons-outlined ml-2 pointer">
                                                                        info
                                                                    </i>
                                                                </Tooltip>
                                                            </label>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : (
                                        'Auction Closed'
                                    )}
                                </div>
                                <div className="d-md-none d-lg-none d-flex flex-wrap align-items-center justify-content-center mt-4">
                                    {product.market_status === 'open' && (
                                        <FavouriteCheckbox
                                            watchlisted={product.watchlistid}
                                            project_id={product.id}
                                            addLabel="Add to Watchlist"
                                            removeLabel="Remove from Watchlist"
                                            className="pvFavorites"
                                            follows={
                                                product.custitem_gvo_producer
                                                    ? product.custitem_gvo_producer
                                                    : ''
                                            }
                                        />
                                    )}
                                </div>
                                {/* ************** */}
                                {product.desc_proc ? (
                                    <>
                                        <h4 className="rvwHdr">Reviews / Information</h4>
                                        <div className="dscptnTxt">
                                            <ReadMore
                                                setHTML={true}
                                                data={product.desc_proc}
                                                limit={300}
                                            />
                                        </div>{' '}
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                    <CustomDialog
                        title={`Follow  ${product.custitem_gvo_producer}`}
                        open={toggleDialog}
                        function={changeDialogStatus}
                    >
                        <h5>Please provide rating.</h5>
                        <Rating
                            name="rating"
                            value={rating}
                            onChange={(event, newValue) => {
                                setRating(newValue)
                            }}
                        />
                        <h5>Please confirm if you want to continue with this action</h5>
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>Cancel</Button>
                            <PrimaryButton type="button" onClick={onFollow} label="Submit" />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </div>
    )
}
export default ProductView
