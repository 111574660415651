import React, { createElement } from 'react'

function ProductItem({ hit, components, id }) {
    return (
        <a href={`/productview/${hit?.objectID}`} className="aa-ItemLink">
            <div className="aa-ItemTitle">
                {console.log(hit, 'checkhit')}
                <components.Highlight hit={hit} attribute="title" />
            </div>
        </a>
    )
}
export default ProductItem
