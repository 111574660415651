import React, { useState } from 'react'
import { removeHTMLTags } from '../../../common/components'
import CustomDialog from '../../organisms/Dialog'

export const ReadMore = ({
    data,
    limit,
    setHTML,
    showInModal,
    customLabel,
    readMoreLabel,
    readLessLabel,
    title,
    ...rest
}) => {
    let cleanedData = removeHTMLTags(data)
    const [readMore, setReadMore] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const toggleReadMore = () => {
        setReadMore(!readMore)
    }

    const manageModalState = () => {
        setModalOpen(!modalOpen)
    }
    return (
        <>
            <p className={`${rest.className || ''}${readMore ? ' show' : ''}`}>
                {setHTML ? (
                    <>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: !readMore ? cleanedData.substring(0, limit) : data,
                            }}
                        ></span>
                    </>
                ) : (
                    <> {cleanedData.substring(0, readMore ? cleanedData.length : limit)}</>
                )}

                {!readMore && cleanedData.length > limit ? '...' : null}
            </p>
            {cleanedData.length > limit && (
                <a
                    className="readMore cursorDecoy"
                    onClick={showInModal ? manageModalState : toggleReadMore}
                    role="Button"
                >
                    {customLabel
                        ? readMore
                            ? readLessLabel
                            : readMoreLabel
                        : `See ${readMore ? 'less' : 'more'}`}
                </a>
            )}

            <CustomDialog
                title={title ? title : 'See Full Details'}
                open={modalOpen}
                className="ConfirmModal termsModal"
                function={manageModalState}
                handleClose={() => setModalOpen(false)}
            >
                <>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: data,
                        }}
                    ></span>
                </>
            </CustomDialog>
        </>
    )
}

export default ReadMore
