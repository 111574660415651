import React, { useReducer } from 'react'
import axios from 'axios'
import CardContext from './cardContext'
import CardReducer from './cardReducer'
import { response } from '../../../common'
import { apiCall } from '../../../../common/api'
import { DirectAPICAll } from './../../../../common/components'

import {
    GET_ALL_CARD_DETAILS,
    GET_CARD_DETAILS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './cardTypes'

const CyberSourceCardState = (props) => {
    const initialState = {
        get_all_card_details: null,
        get_card_details: null,
        responseStatus: null,
    }
    const [state, dispatch] = useReducer(CardReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const createCard = async (formData) => {
        const from = 'createCard'
        console.log('formData', formData)
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/cybersource'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllCards = async (formData) => {
        const from = 'listCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/cybersource'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CARD_DETAILS,
                    payload: {
                        records: res.data.data.responseData.data.length
                            ? res.data.data.responseData.data
                            : [],
                        has_more: res.data.data.responseData.has_more,
                    },
                })
            } else {
                resp.commonResponse(res, 'card_source_list')
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_list')
        }
    }

    const updateCard = async (formData) => {
        const from = 'updateCard'
        console.log('formData', formData)
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/cybersource'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteCard = async (formData) => {
        const from = 'deleteCard'
        console.log('formData', formData)
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/cybersource'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CardContext.Provider
            value={{
                responseStatus: state.responseStatus,
                get_all_card_details: state.get_all_card_details,
                get_card_details: state.get_card_details,
                createCard,
                getAllCards,
                updateCard,
                deleteCard,
                clearResponse,
            }}
        >
            {props.children}
        </CardContext.Provider>
    )
}

export default CyberSourceCardState
