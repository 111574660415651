import React, { useReducer } from 'react'
import NetSuiteContext from './netSuiteContext'
import NetSuiteReducer from './netSuiteReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import {
    GET_ALL_CUSTOMER_ORDERS,
    GET_ALL_VENDOR_SELLING,
    GET_ALL_VENDOR_SOLD,
    GET_ALL_CUSTOMER_PAID,
    GET_ALL_CUSTOMER_UNPAID,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
} from './netSuiteTypes'
const FileSaver = require('file-saver')

const NetSuiteState = (props) => {
    const initialState = {
        responseStatus: null,
        vendor_selling: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        vendor_sold: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        customer_orders: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        customer_paid: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
        customer_unpaid: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            from: '',
        },
    }

    const [state, dispatch] = useReducer(NetSuiteReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getCustomerOrders = async (formData, fromVariable) => {
        const from = 'getCustomerOrders'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'third_party/netsuite'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CUSTOMER_ORDERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable || from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getVendorSelling = async (formData, fromVariable) => {
        const from = 'getVendorSelling'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'third_party/netsuite'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_VENDOR_SELLING,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable || from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getVendorSold = async (formData, fromVariable) => {
        const from = 'getVendorSold'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'third_party/netsuite'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_VENDOR_SOLD,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable || from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const getCustomerPaid = async (formData, fromVariable) => {
        const from = 'getCustomerPaid'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'third_party/netsuite'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CUSTOMER_PAID,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable || from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getCustomerUnPaid = async (formData, fromVariable) => {
        const from = 'getCustomerUnPaid'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'third_party/netsuite'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CUSTOMER_UNPAID,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable || from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllExportDownload = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'export', formData, '', 'third_party/netsuite'),
            ])
            const from = 'ReportDisplay'
            if (res.data.status === 'success') {
                const csvData = new Blob([res.data.data.responseData.records], {
                    type: 'text/csv;charset=utf-8;',
                })
                FileSaver.saveAs(csvData, res.data.data.responseData.filename)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <NetSuiteContext.Provider
            value={{
                responseStatus: state.responseStatus,
                customer_orders: state.customer_orders,
                vendor_selling: state.vendor_selling,
                vendor_sold: state.vendor_sold,
                customer_paid: state.customer_paid,
                customer_unpaid: state.customer_unpaid,
                getAllExportDownload,
                clearResponse,
                getCustomerOrders,
                getVendorSelling,
                getVendorSold,
                getCustomerPaid,
                getCustomerUnPaid,
            }}
        >
            {props.children}
        </NetSuiteContext.Provider>
    )
}

export default NetSuiteState
