import {
    USER_LOADED,
    NORTH_CAPITAL_BANK_DETAILS,
    NORTH_CAPITAL_CARD_DETAILS,
    NORTH_CAPITAL_CARD_LINK,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    RESPONSE_STBIDS,
    RESPONSE_ASSET_TOTAL,
    PARTY_DOCUMENTS,
    PARTY_OWNER_DOCUMENTS,
} from './northcapitalTypes'
export default (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                northCapitalUser: action.payload.data,
            }
        case NORTH_CAPITAL_BANK_DETAILS:
            return {
                ...state,
                north_capital_bank_details: action.payload,
            }
        case NORTH_CAPITAL_CARD_LINK:
            return {
                ...state,
                north_capital_card_link: action.payload,
            }

        case NORTH_CAPITAL_CARD_DETAILS:
            return {
                ...state,
                north_capital_card_details: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case RESPONSE_STBIDS:
            return {
                ...state,
                stbids_list: action.payload,
            }
        case RESPONSE_ASSET_TOTAL:
            return {
                ...state,
                asset_total: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case PARTY_DOCUMENTS:
            return {
                ...state,
                party_documents: action.payload.data,
            }
        case PARTY_OWNER_DOCUMENTS:
            return {
                ...state,
                party_owner_documents: action.payload.data,
            }

        default:
            return state
    }
}
