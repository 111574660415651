import React, { useState, useContext, useEffect } from 'react'
import './Footer.css'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { BLACK_LOGO, SITE_NAME } from '../../../../Utils'
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useToggle from '../../../common/useToggle'

const Footer = () => {
    const [serviceIsToggled, toggleService] = useToggle()
    const [generalIsToggled, toggleGeneral] = useToggle()
    const [cwcIsToggled, toggleCsw] = useToggle()
    const autopltContext = useContext(AutopltContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            addCustomer(values)
        },
    })

    const subscribe = [
        {
            label: 'Subscribe to our mailing list',
            placeholder: 'Enter your email address',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer className="noPrint">
            <div className="footerTag">
                <h3>FINE WINE WITH IMPECCABLE PROVENANCE</h3>
            </div>
            <div className="footCnt ">
                <div className="customContainer">
                    <div className="footLinks">
                        <div className="footLogo d-flex">
                            <img
                                src={BLACK_LOGO}
                                alt="Chicago Wine Company Logo"
                                className="mw-100"
                            />
                        </div>
                        <div className={`footer-content ${serviceIsToggled ? 'show' : ''}`}>
                            <h4>
                                Customer Service
                                <span onClick={toggleService}>
                                    <ExpandMoreIcon />
                                </span>
                            </h4>
                            <ul className="footer-sub-links">
                                <li>
                                    <Link to="/terms_of_use" id="footer_termsandcondition">
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/delivery" id="footer_delivery">
                                        Delivery, Collection & Storage
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={`footer-content ${generalIsToggled ? 'show' : ''}`}>
                            <h4>
                                General
                                <span onClick={toggleGeneral}>
                                    <ExpandMoreIcon />
                                </span>
                            </h4>
                            <ul className="footer-sub-links">
                                <li>
                                    <Link to="/contact_us" id="footer_contactus">
                                        Contact Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy_policy" id="footer_privacypolicy">
                                        Privacy & Accessibility
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={`footer-content ${cwcIsToggled ? 'show' : ''}`}>
                            <h4>
                                The Chicago Wine Company
                                <span onClick={toggleCsw}>
                                    <ExpandMoreIcon />
                                </span>
                            </h4>
                            <ul className="footer-sub-links">
                                <li>
                                    <a
                                        href="https://goo.gl/maps/DsqHNAeq8aHSKiVT9"
                                        target="_blank"
                                        rel="noreferrer"
                                        id="footer_address"
                                    >
                                        835 N. Central Ave, Wood Dale, IL 60191
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@tcwc.com" id="footer_email">
                                        info@tcwc.com
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:630-594-2972" id="footer_phonenumber">
                                        630-594-2972
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <ul className="footSocial d-flex justify-content-start">
                            <li>
                                <a
                                    href="https://www.instagram.com/chicagowineco/"
                                    target="_blank"
                                    rel="noreferrer"
                                    id="instagram_logo"
                                >
                                    <img src="/assets/svg/ig_black.svg" alt="Instagram logo" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p className="footCopyright text-center px-4" dir="ltr">
                © {SITE_NAME} {new Date().getFullYear()}. All rights reserved
            </p>
        </footer>
    )
}

export default Footer
