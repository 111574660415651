import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { LOGO } from '../../../../Utils'
import { Link } from 'react-router-dom'
import { Badge, Button, Divider, ListItem, SwipeableDrawer } from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../../product/common/components'
import './Header.css'
import BidHistory from '../../../components/organisms/BidHistory'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import Loaders from '../Loaders'
import { Popover } from '@material-ui/core'
import CategoryDropdown from './CategoryDropdown'
import CartContext from '../../../../product/context/cart/cartContext'
import UserContext from '../../../../product/context/user/userContext'

import { getAlgoliaResults } from '@algolia/autocomplete-js'
import algoliasearch from 'algoliasearch'
import { Autocomplete } from './autocomplete'
import ProductItem from './ProductItem'

const Header = () => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const commonContext = useContext(CommonContext)
    const buynowContext = useContext(BuynowContext)
    const cartContext = useContext(CartContext)
    const userContext = useContext(UserContext)

    const { responseStatus: responseStatusUser } = userContext
    const { setAlert } = alertContext
    const { loadUser, user, isAuthenticated, loadPendingCount, isAdmin, cartCount, logout } =
        authContext

    const { responseStatus: cartResponse } = cartContext

    const { getAllCartProducts, refreshCart, search_allproducts, responseStatus } = buynowContext

    const [state, setState] = useState({
        right: false,
        top: false,
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)
    const [specialOffer, setSpecialOffers] = useState(null)
    const [brWines, setBrWines] = useState(false)
    const [moreDrop, setMoreDrop] = useState(null)

    const { categoryTypes, allConfigurationVariables, setSearchTempValue } = customCommonContext

    const { searchValue, setSearchValue, clear_header, isLoading } = commonContext
    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setCartProducts(search_allproducts.cartrecords.length ? search_allproducts.cartrecords : [])
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    // useEffect(() => {
    //     if (searchValue) {
    //         handleRedirectInternal(history, `search`)
    //     }
    // }, [searchValue])

    let ref = useRef()

    useEffect(() => {
        const handler = (event) => {
            if (brWines && ref.current && !ref.current.contains(event.target)) {
                setTimeout(() => {
                    setBrWines(false)
                }, 100)
            }
        }
        document.addEventListener('mousedown', handler)
        document.addEventListener('touchstart', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
            document.removeEventListener('touchstart', handler)
        }
    }, [brWines])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }

    const handleMenu = (event, val) => {
        if (val === 'splOfrs') {
            setSpecialOffers(event.currentTarget)
        } else {
            if (val === 'brWines') {
                setBrWines(event.currentTarget)
            } else {
                if (val === 'moreDrop') {
                    setMoreDrop(event.currentTarget)
                } else {
                    setanchormenu(event.currentTarget)
                }
            }
        }
    }

    const handleMenuClose = (val) => {
        if (val === 'splOfrs') {
            setSpecialOffers(false)
        } else {
            if (val === 'brWines') {
                setBrWines(false)
            } else {
                if (val === 'moreDrop') {
                    setMoreDrop(false)
                } else {
                    setanchormenu(false)
                }
            }
        }
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            // console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const getCountryObject = (value) => {
        let custitem_gvo_country = {
            type: 'array',
            field: 'cp.custitem_gvo_country',
        }
        // Assign value to searchbar object
        custitem_gvo_country.value = [value]

        return {
            limit: global.initialPageLimit,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                custitem_gvo_country,
            },
        }
    }

    const getRegionObject = (value) => {
        let custitemctregion = {
            type: 'array',
            field: 'cp.custitemctregion',
        }
        let custitem_gvo_country = {
            type: 'array',
            field: 'cp.custitem_gvo_country',
        }
        // Assign value to searchbar object
        custitemctregion.value = [value]

        let selectedRegion = categoryTypes.filter((type) => type.value === value)
        let selectedCountry = categoryTypes.filter(
            (type) => type.id == selectedRegion?.[0].level_id,
        )
        custitem_gvo_country.value = [selectedCountry?.[0].value]

        return {
            limit: global.initialPageLimit,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                custitemctregion,
                custitem_gvo_country,
            },
        }
    }

    const getSubRegionObject = (value) => {
        let custitemctsubregion = {
            type: 'array',
            field: 'cp.custitemctsubregion',
        }
        let custitemctregion = {
            type: 'array',
            field: 'cp.custitemctregion',
        }
        let custitem_gvo_country = {
            type: 'array',
            field: 'cp.custitem_gvo_country',
        }

        // Assign value to searchbar object
        custitemctsubregion.value = [value]

        let selectedSubRegion = categoryTypes.filter((type) => type.value === value)
        let selectedRegion = categoryTypes.filter(
            (type) => type.id === selectedSubRegion[0].level_id,
        )
        let selectedCountry = categoryTypes.filter((type) => type.id === selectedRegion[0].level_id)

        custitemctregion.value = [selectedRegion[0].value]
        custitem_gvo_country.value = [selectedCountry[0].value]

        return {
            limit: global.initialPageLimit,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                custitemctsubregion,
                custitemctregion,
                custitem_gvo_country,
            },
        }
    }

    const getSearchBarObjet = (value) => {
        let searchBarObj = global.initalSearchValues.searchbar

        // Assign value to searchbar object
        searchBarObj.value = value

        return {
            limit: global.initialPageLimit,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: searchBarObj,
            },
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchAutocompleteTextChange = (item, reset) => {
        let searchQuery = reset ? '' : item.state.query

        setSearchText(item.state.query)
        if (searchQuery != '') {
            if (location.pathname != '/search') {
                let query = generateQueryParams('search', searchQuery)
                handleRedirectInternal(history, `search?${query}`)
            } else {
                setSearchValue(getSearchBarObjet(searchQuery))
            }
        } else {
            setSearchValue(getSearchBarObjet(searchQuery))
        }
        return true
    }

    const generateQueryParams = (type, id) => {
        let queryObject = {}
        if (type === 'country') {
            queryObject.country = id
        } else if (type === 'region') {
            queryObject.region = id
            let selectedRegion = categoryTypes.filter((type) => type.value === id)
            let selectedCountry = categoryTypes.filter(
                (type) => type.id == selectedRegion?.[0].level_id,
            )
            queryObject.country = selectedCountry?.[0].value
        } else if (type === 'subregion') {
            // Assign value to searchbar object
            queryObject.subvalue = id

            let selectedSubRegion = categoryTypes.filter((type) => type.value === id)
            let selectedRegion = categoryTypes.filter(
                (type) => type.id === selectedSubRegion[0].level_id,
            )
            let selectedCountry = categoryTypes.filter(
                (type) => type.id === selectedRegion[0].level_id,
            )

            queryObject.region = selectedRegion[0].value
            queryObject.country = selectedCountry[0].value
        } else if (type === 'search') {
            queryObject.searchQuery = id
        }

        const params = new URLSearchParams(queryObject)
        return params.toString()
    }

    const handleCountryButtonClick = (id, e) => {
        e.stopPropagation()
        if (id != '') {
            setSearchTempValue({})
            if (location.pathname != '/search') {
                let query = generateQueryParams('country', id)
                handleRedirectInternal(history, `search?${query}`)
            } else {
                setSearchValue(getCountryObject(id))
            }
        } else {
            setSearchValue(getCountryObject(id))
        }
        handleMenuClose('brWines')
    }

    const handleRegionButtonClick = (id, e) => {
        e.stopPropagation()
        if (id != '') {
            setSearchTempValue({})
            if (location.pathname != '/search') {
                let query = generateQueryParams('region', id)
                handleRedirectInternal(history, `search?${query}`)
            } else {
                setSearchValue(getRegionObject(id))
            }
        } else {
            setSearchValue(getRegionObject(id))
        }
        handleMenuClose('brWines')
    }

    const handleSubRegionButtonClick = (id, e) => {
        e.stopPropagation()
        if (id != '') {
            setSearchTempValue({})
            if (location.pathname != '/search') {
                let query = generateQueryParams('subregion', id)
                handleRedirectInternal(history, `search?${query}`)
            } else {
                setSearchValue(getSubRegionObject(id))
            }
        }
        handleMenuClose('brWines')
    }

    const handleSearchButtonClick = () => {
        if (searchText != '') {
            if (location.pathname != '/search') {
                let query = generateQueryParams('search', searchText)
                handleRedirectInternal(history, `search?${query}`)
            } else {
                setSearchValue(getSearchBarObjet(searchText))
            }

            setState({ ...state, top: false })
        } else {
            setSearchValue(getSearchBarObjet(searchText))
        }
        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
    }

    const handleSearchKeyDown = (e) => {
        // if (e.key === 'Enter') {
        if (searchText != '') {
            if (location.pathname != '/search') {
                let query = generateQueryParams('search', searchText)
                handleRedirectInternal(history, `search?${query}`)
            } else {
                setSearchValue(getSearchBarObjet(searchText))
            }
        } else {
            setSearchValue(getSearchBarObjet(searchText))
        }
        // }
    }

    useEffect(() => {
        if (
            searchValue &&
            searchValue.filters &&
            searchValue.filters.searchbar &&
            searchValue.filters.searchbar.value
        ) {
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    let auctionFilter = {
        table: 'buynow',
        field: 'id',
        extraField: '',
        filters: {
            buynow_autype: {
                value: 'live',
                type: 'in',
                field: 'b.buynow_autype',
            },
            paid: {
                value: '0',
                type: 'in',
                field: 'b.paid',
            },
            partial: {
                value: '0',
                type: 'in',
                field: 'b.partial',
            },
        },
    }

    let buyNowFilter = {
        table: 'cart_temp',
        field: 'cart_id',
        extraField: '',
        filters: {
            paid: {
                value: '0',
                type: 'in',
                field: 'crt.paid',
            },
        },
    }

    useEffect(() => {
        if (searchText && history.location.pathname !== '/search') {
            setSearchText('')
        }
    }, [history.location.pathname])

    useEffect(() => {
        if (isAuthenticated) {
            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    console.log('responseStatus', responseStatus, 'responsestatus cart contxt', cartResponse)

    /*Added this to load pending count onclicking buynow without redirecting*/
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    loadPendingCount(buyNowFilter, 'buynowCount')
                    loadPendingCount(auctionFilter, 'auctionCount')
                }
            }
        }
    }, [responseStatus])

    /*Added this to load pending count on removing the product from the cart*/
    useEffect(() => {
        if (cartResponse) {
            if (
                cartResponse.from === 'cartchange' ||
                cartResponse.from === 'addToCart' ||
                responseStatus.from === 'refreshCart'
            ) {
                if (cartResponse.status === 'success') {
                    loadPendingCount(buyNowFilter, 'buynowCount')
                    loadPendingCount(auctionFilter, 'auctionCount')
                }
            }
        } else if (responseStatus) {
            if (responseStatus.from === 'cartchange' || responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    loadPendingCount(buyNowFilter, 'buynowCount')
                    loadPendingCount(auctionFilter, 'auctionCount')
                }
            }
        }
    }, [cartResponse, responseStatus])

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference' ||
                responseStatusUser.from === 'userAddress'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                    if (responseStatusUser.from === 'userAddress') {
                        refreshCart(null, true)
                    }
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        setSearchText('')
    }, [clear_header])

    const appId = process.env.REACT_APP_ALGOLIA_APPID
    const apiKey = process.env.REACT_APP_ALGOLIA_APIKEY
    const searchClient = algoliasearch(appId, apiKey)

    console.log('searchText', searchText)
    return (
        <>
            {useCustomMediaQuery('(min-width: 1024px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <Loaders name="home" isLoading={isLoading} />
                    <header className="mainHeader noPrint">
                        <div className="customContainer d-flex justify-content-between align-items-center h-100">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Link to="/" id="chicagowine_logo">
                                    <img src={LOGO} alt="Chicago Wine Company Logo" />
                                </Link>
                            </div>
                            <div className="rtContainer w-100">
                                <div className="headLt d-flex justify-content-end align-items-center pb-4">
                                    <div className="srchContainer d-flex justify-content-end align-items-center">
                                        <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                            <Autocomplete
                                                openOnFocus={true}
                                                onSubmit={(stateData) => {
                                                    stateData.setIsOpen(false)
                                                    handleSearchAutocompleteTextChange(
                                                        stateData,
                                                        false,
                                                    )
                                                }}
                                                getSources={({ query }) => [
                                                    {
                                                        sourceId: 'products',
                                                        onSelect({
                                                            setIsOpen,
                                                            setQuery,
                                                            event,
                                                            item,
                                                        }) {
                                                            handleRedirectInternal(
                                                                history,
                                                                `productview/${item?.objectID}`,
                                                            )
                                                        },
                                                        getItems() {
                                                            return getAlgoliaResults({
                                                                searchClient,
                                                                queries: [
                                                                    {
                                                                        indexName:
                                                                            process.env
                                                                                .REACT_APP_ALGOLIA_INDEX,
                                                                        query,
                                                                    },
                                                                ],
                                                            })
                                                        },

                                                        templates: {
                                                            header() {
                                                                return 'Suggestions'
                                                            },
                                                            item({ item, components }) {
                                                                return (
                                                                    <>
                                                                        {item?.objectID ? (
                                                                            <div className="aa-ItemTitle">
                                                                                <components.Highlight
                                                                                    hit={item}
                                                                                    attribute="title"
                                                                                />
                                                                            </div>
                                                                        ) : null}
                                                                    </>
                                                                )
                                                            },
                                                        },
                                                    },
                                                ]}
                                            />

                                            {/* <input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search your favorite wines..."
                                                aria-label="Search"
                                                value={searchText}
                                                onChange={handleSearchTextChange}
                                                onKeyDown={handleSearchKeyDown}
                                            />
                                            <Button
                                                className="btn"
                                                onClick={handleSearchButtonClick}
                                            >
                                                <span className="material-icons">search</span>
                                            </Button> */}
                                        </div>
                                    </div>
                                    <div className="rtNvCntnr d-flex align-items-center">
                                        {!isAuthenticated ? (
                                            <>
                                                <div className="usrLogin d-flex align-items-center">
                                                    <span className="material-icons mr-2">
                                                        account_circle
                                                    </span>
                                                    <NavLink
                                                        to="/login"
                                                        ActiveclassName="active"
                                                        className="mr-2"
                                                        id="header_signin"
                                                    >
                                                        Sign In
                                                    </NavLink>
                                                    /
                                                    <NavLink
                                                        to="/registration"
                                                        className="ml-2"
                                                        id="header_register"
                                                    >
                                                        Register
                                                    </NavLink>
                                                </div>
                                            </>
                                        ) : (
                                            <ul className="headAucthNav d-flex justify-content-start align-items-center">
                                                <li>
                                                    <Button
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={handleMenu}
                                                        id="header_usercircle"
                                                    >
                                                        <span className="material-icons">
                                                            account_circle
                                                        </span>{' '}
                                                        {user?.first_name} {user?.last_name}
                                                        <span className="material-icons">
                                                            keyboard_arrow_down
                                                        </span>{' '}
                                                    </Button>

                                                    <Popover
                                                        id={'simple-menu'}
                                                        open={Boolean(anchormenu)}
                                                        anchorEl={anchormenu}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <ul
                                                            className={`headerDropMenu ${
                                                                document.body.dir === 'rtl' && 'rtl'
                                                            }`}
                                                        >
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'auctions/all',
                                                                    )
                                                                }
                                                                id="header_mybids"
                                                            >
                                                                <span className="material-icons">
                                                                    gavel
                                                                </span>
                                                                My Bids
                                                            </ListItem>

                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'myWines/purchased',
                                                                    )
                                                                }
                                                                id="header_mywines"
                                                            >
                                                                <span className="material-icons">
                                                                    shopping_basket
                                                                </span>
                                                                My Wines
                                                            </ListItem>

                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'watchlist/watchlist',
                                                                    )
                                                                }
                                                                id="header_mywatchlist"
                                                            >
                                                                <span className="material-icons">
                                                                    favorite
                                                                </span>
                                                                My Watchlist
                                                            </ListItem>

                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    handleClose(
                                                                        history,
                                                                        'account/profile',
                                                                    )
                                                                }
                                                                id="header_myaccount"
                                                            >
                                                                <span className="material-icons">
                                                                    person
                                                                </span>
                                                                My Account
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                onClick={() => onLogOut()}
                                                                id="header_logout"
                                                            >
                                                                <span className="material-icons">
                                                                    power_settings_new
                                                                </span>
                                                                Logout
                                                            </ListItem>
                                                        </ul>
                                                    </Popover>
                                                </li>
                                                <li className="headCart">
                                                    <Button
                                                        onClick={() =>
                                                            handleClose(history, 'buynowcart')
                                                        }
                                                        id="header_shoppingcart"
                                                    >
                                                        <Badge
                                                            badgeContent={count.buynowCount}
                                                            color="primary"
                                                        >
                                                            <span className="material-icons">
                                                                shopping_cart
                                                            </span>
                                                        </Badge>
                                                    </Button>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <div className="menuRtOtr">
                                    <div className="headRt deskNav d-flex justify-content-end align-items-center">
                                        <ul className="d-flex justify-content-end align-items-center w-100">
                                            <li>
                                                <NavLink
                                                    id="header_home"
                                                    activeClassName="active"
                                                    exact
                                                    to="/"
                                                >
                                                    Home
                                                </NavLink>
                                            </li>
                                            {/* <li>
                                                <a activeClassName="active" href="#">
                                                    Special Offers
                                                </a>
                                            </li> */}
                                            {/* <li>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleMenu(e, 'splOfrs')}
                                                >
                                                    Special Offers{' '}
                                                    <span className="material-icons ml-2">
                                                        expand_more
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(specialOffer)}
                                                    anchorEl={specialOffer}
                                                    onClose={() => handleMenuClose('splOfrs')}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <ul
                                                        className={`headerDropMenu ${
                                                            document.body.dir === 'rtl' && 'rtl'
                                                        }`}
                                                    >
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(history, 'auctions/all')
                                                            }
                                                        >
                                                            abcd
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li> */}
                                            <li>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    id="header_browsewine"
                                                    aria-expanded={brWines ? 'true' : 'false'}
                                                    onMouseEnter={() => setBrWines((prev) => !prev)}
                                                    onClick={() => {
                                                        setSearchTempValue({})
                                                        handleRedirectInternal(history, 'search')
                                                    }}
                                                >
                                                    Browse Wines{' '}
                                                    <span className="material-icons ml-2">
                                                        expand_more
                                                    </span>
                                                </Button>
                                                <CategoryDropdown
                                                    anchor={ref}
                                                    allCategories={categoryTypes}
                                                    submenus={categoryTypes}
                                                    onMouseLeave={() => setBrWines((prev) => !prev)}
                                                    isVisible={brWines}
                                                    onCountryClick={handleCountryButtonClick}
                                                    onRegionClick={handleRegionButtonClick}
                                                    onSubregionClick={handleSubRegionButtonClick}
                                                    depthLevel={0}
                                                />
                                            </li>
                                            <li>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auction"
                                                    id="header_auction"
                                                >
                                                    Auctions
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/selling_wine"
                                                    id="header_sellyourwine"
                                                >
                                                    Sell Your Wine
                                                </NavLink>
                                            </li>
                                            <li>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleMenu(e, 'moreDrop')}
                                                    id="header_more"
                                                >
                                                    More
                                                    <span className="material-icons ml-2">
                                                        expand_more
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(moreDrop)}
                                                    anchorEl={moreDrop}
                                                    onClose={() => handleMenuClose('moreDrop')}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <ul
                                                        className={`headerDropMenu ${
                                                            document.body.dir === 'rtl' && 'rtl'
                                                        }`}
                                                    >
                                                        {/* <ListItem
                                                            button
                                                            onClick={() => {
                                                                handleClose(history, 'contact'),
                                                                    handleMenuClose('moreDrop')
                                                            }}
                                                            id="header_wineclub"
                                                        >
                                                            Wine Club
                                                        </ListItem> */}
                                                        <ListItem
                                                            button
                                                            onClick={() => {
                                                                handleClose(history, 'about'),
                                                                    handleMenuClose('moreDrop')
                                                            }}
                                                            id="header_aboutus"
                                                        >
                                                            About Us
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() => {
                                                                handleClose(history, 'delivery'),
                                                                    handleMenuClose('moreDrop')
                                                            }}
                                                            id="header_delivery"
                                                        >
                                                            Delivery, Collection & Storage
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() => {
                                                                handleClose(history, 'contact_us'),
                                                                    handleMenuClose('moreDrop')
                                                            }}
                                                            id="header_contactus"
                                                        >
                                                            Contact Us
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() => {
                                                                handleClose(
                                                                    history,
                                                                    'privacy_policy',
                                                                ),
                                                                    handleMenuClose('moreDrop')
                                                            }}
                                                            id="header_privacy"
                                                        >
                                                            Privacy & Accessibility
                                                        </ListItem>
                                                        {allConfigurationVariables.header_first_page ? (
                                                            <ListItem
                                                                button
                                                                onClick={() => {
                                                                    handleClose(
                                                                        history,
                                                                        'header_first_page',
                                                                    ),
                                                                        handleMenuClose('moreDrop')
                                                                }}
                                                            >
                                                                {
                                                                    allConfigurationVariables.header_first_page
                                                                }
                                                            </ListItem>
                                                        ) : null}
                                                        {allConfigurationVariables.header_second_page ? (
                                                            <ListItem
                                                                button
                                                                onClick={() => {
                                                                    handleClose(
                                                                        history,
                                                                        'header_second_page',
                                                                    ),
                                                                        handleMenuClose('moreDrop')
                                                                }}
                                                            >
                                                                {
                                                                    allConfigurationVariables.header_second_page
                                                                }
                                                            </ListItem>
                                                        ) : null}
                                                        {allConfigurationVariables.header_third_page ? (
                                                            <ListItem
                                                                button
                                                                onClick={() => {
                                                                    handleClose(
                                                                        history,
                                                                        'header_third_page',
                                                                    ),
                                                                        handleMenuClose('moreDrop')
                                                                }}
                                                            >
                                                                {
                                                                    allConfigurationVariables.header_third_page
                                                                }
                                                            </ListItem>
                                                        ) : null}
                                                        {allConfigurationVariables.header_fourth_page ? (
                                                            <ListItem
                                                                button
                                                                onClick={() => {
                                                                    handleClose(
                                                                        history,
                                                                        'header_fourth_page',
                                                                    ),
                                                                        handleMenuClose('moreDrop')
                                                                }}
                                                            >
                                                                {
                                                                    allConfigurationVariables.header_fourth_page
                                                                }
                                                            </ListItem>
                                                        ) : null}
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Link to="/">
                                <img src={LOGO} alt="The Chicago Wine Company Logo" />
                            </Link>
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li>
                                    <Button onClick={toggleDrawer('top', true)}>
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>
                                {isAuthenticated && (
                                    <li className="headCart">
                                        <Link to="/buynowcart">
                                            <Badge color="primary">
                                                <span className="material-icons">
                                                    shopping_cart
                                                </span>
                                            </Badge>
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder={'search'}
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn" onClick={handleSearchButtonClick}>
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            src={LOGO}
                                            alt="The Chicago Wine Company Logo"
                                            height="60"
                                        />
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks">
                                    {/* <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/auction"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">live_tv</span>
                                            {t('live_auctions')}
                                        </NavLink>
                                    </ListItem> */}

                                    {/* <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/search"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">category</span>
                                            Products
                                        </NavLink>
                                    </ListItem> */}

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/search"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">ads_click</span>
                                            Browse Wines
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/auction"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">gavel</span>
                                            Auctions
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to={'/account/profile'}
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">person</span>
                                                    My Account
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/auctions/all"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/salesorder/all"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        shopping_bag
                                                    </span>
                                                    Sales Orders
                                                </NavLink>
                                            </ListItem> */}

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/myWines/purchased"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        shopping_basket
                                                    </span>
                                                    My Wines
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/watchlist/watchlist"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    My Watchlist
                                                </NavLink>
                                            </ListItem>

                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/transactions"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        swap_vert
                                                    </span>
                                                    {t('my_transactions')}
                                                </NavLink>
                                            </ListItem> */}
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/salesorder/all"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    {'My Sales Order'}
                                                </NavLink>
                                            </ListItem> */}

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/saved_search"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    My saved searches
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    {/* <ListItem button>
                                        <a
                                            href="tel:048897585"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">call</span>
                                            {t('within_uae')}:
                                            <span className="w-100 hnContact">04 889 7585</span>
                                        </a>
                                    </ListItem>
                                    <ListItem button>
                                        <a
                                            href="tel: +97148897585"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">call</span>
                                            {t('international')}:
                                            <span className="w-100 hnContact">+971 4 889 7585</span>
                                        </a>
                                    </ListItem> */}

                                    {/* <ListItem
                                        button
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        aria-expanded={brWines ? 'true' : 'false'}
                                        onMouseEnter={() => setBrWines((prev) => !prev)}
                                        onClick={() => handleRedirectInternal(history, 'search')}
                                    >
                                        Browse Wines{' '}
                                        <span className="material-icons ml-2">expand_more</span>
                                    </ListItem>
                                    <CategoryDropdown
                                        anchor={ref}
                                        allCategories={categoryTypes}
                                        submenus={categoryTypes}
                                        isVisible={brWines}
                                        onCountryClick={handleCountryButtonClick}
                                        onRegionClick={handleRegionButtonClick}
                                        onSubregionClick={handleSubRegionButtonClick}
                                        depthLevel={0}
                                    /> */}

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/selling_wine"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">sell</span>
                                            Sell Your Wine
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/about"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">info</span>
                                            About
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/delivery"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">workspaces</span>
                                            Delivery & Collection
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/contact_us"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <span className="material-icons">contact_support</span>
                                            Contact
                                        </NavLink>
                                    </ListItem>

                                    {isAuthenticated ? (
                                        <ListItem button onClick={() => onLogOut()}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    ) : (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    to="/login"
                                                    ActiveclassName="active"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    to="/registration"
                                                    ActiveclassName="active"
                                                    onClick={toggleDrawer('right', false)}
                                                >
                                                    <span className="material-icons">
                                                        account_circle
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <p className="dvHdr">MORE</p>

                                    {/* <ListItem
                                        className="sbLnk"
                                        button
                                        onClick={() => {
                                            handleRedirectInternal(history, 'contact'),
                                                toggleDrawer('right', false)
                                        }}
                                    >
                                        Wine Club
                                    </ListItem> */}
                                    <ListItem button className="sbLnk">
                                        <NavLink
                                            to="/about"
                                            ActiveclassName="active"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            About Us
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button className="sbLnk">
                                        <NavLink
                                            to="/delivery"
                                            ActiveclassName="active"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            Delivery, Collection & Storage
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button className="sbLnk">
                                        <NavLink
                                            to="/contact_us"
                                            ActiveclassName="active"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            Contact Us
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button className="sbLnk">
                                        <NavLink
                                            to="/privacy_policy"
                                            ActiveclassName="active"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            Privacy & Accessibility
                                        </NavLink>
                                    </ListItem>
                                    {allConfigurationVariables.header_first_page ? (
                                        <ListItem button className="sbLnk">
                                            <NavLink
                                                to="/header_first_page"
                                                ActiveclassName="active"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                {allConfigurationVariables.header_first_page}
                                            </NavLink>
                                        </ListItem>
                                    ) : null}
                                    {allConfigurationVariables.header_second_page ? (
                                        <ListItem button className="sbLnk">
                                            <NavLink
                                                to="/header_second_page"
                                                ActiveclassName="active"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                {allConfigurationVariables.header_second_page}
                                            </NavLink>
                                        </ListItem>
                                    ) : null}
                                    {allConfigurationVariables.header_third_page ? (
                                        <ListItem button className="sbLnk">
                                            <NavLink
                                                to="/header_third_page"
                                                ActiveclassName="active"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                {allConfigurationVariables.header_third_page}
                                            </NavLink>
                                        </ListItem>
                                    ) : null}
                                    {allConfigurationVariables.header_fourth_page ? (
                                        <ListItem button className="sbLnk">
                                            <NavLink
                                                to="/header_fourth_page"
                                                ActiveclassName="active"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                {allConfigurationVariables.header_fourth_page}
                                            </NavLink>
                                        </ListItem>
                                    ) : null}
                                </ul>
                                {/*<ul className="navRespLinks">
                                    <ListItem button>
                                        <LanguageSwitch />
                                    </ListItem>
                                    </ul>*/}
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <BidHistory />
        </>
    )
}
export default Header
