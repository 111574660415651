import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { scrollTop } from '../../product/common/components'
import CommonContext from '../../product/context/common/commonContext'
import './StaticPages.css'

const Static = (props) => {
    const commonContext = useContext(CommonContext)

    const { static_page, getStaticPage } = commonContext
    let [viewStatic, setviewStatic] = useState([])

    const [search, setSearch] = useState({
        id: 0,
    })

    useEffect(() => {
        if (props.location.pathname === '/about') {
            setSearch({ id: 'about' })
        } else if (props.location.pathname === '/services') {
            setSearch({ id: 'services' })
        } else if (props.location.pathname === '/help') {
            setSearch({ id: 'help' })
        } else if (props.location.pathname === '/guidelines') {
            setSearch({ id: 'guidelines' })
        } else if (props.location.pathname === '/privacy_policy') {
            setSearch({ id: 'privacy_policy' })
        } else if (props.location.pathname === '/terms_of_use') {
            setSearch({ id: 'terms_of_use' })
        } else if (props.location.pathname === '/faq') {
            setSearch({ id: 'faq' })
        } else if (props.location.pathname === '/selling_wine') {
            setSearch({ id: 'selling_wine' })
        } else if (props.location.pathname === '/delivery') {
            setSearch({ id: 'delivery' })
        } else if (props.location.pathname === '/contact_us') {
            setSearch({ id: 'contact_us' })
        } else if (props.location.pathname === '/header_first_page') {
            setSearch({ id: 'header_first_page' })
        } else if (props.location.pathname === '/header_second_page') {
            setSearch({ id: 'header_second_page' })
        } else if (props.location.pathname === '/header_third_page') {
            setSearch({ id: 'header_third_page' })
        } else if (props.location.pathname === '/header_fourth_page') {
            setSearch({ id: 'header_fourth_page' })
        }

        scrollTop()
    }, [props.location.pathname])

    useEffect(() => {
        if (search.id !== 0) {
            getStaticPage(search)
        }
    }, [search])

    useEffect(() => {
        setviewStatic(static_page.record ? static_page.record : null)
    }, [static_page])
    const sellYourWine = [
        {
            img: '/assets/svg/reliable.svg',
            title: 'Free Professional Appraisal',
            desc: 'Simply send us a list of your wines and TCWC will use nearly 50 years of experience to provide an accurate appraisal and optimal auction strategy to suit your collection',
        },
        {
            img: '/assets/svg/winemarket.svg',
            title: 'Access to Markets',
            desc: 'TCWC has a dedicated team that will market your wines to passionate collectors and trade members actively seeking top wines.',
        },
        {
            img: '/assets/svg/access.svg',
            title: '24/7 Visibility',
            desc: 'Online access to your collection once inventoried and entered into our system',
        },
        {
            img: '/assets/svg/shipping.svg',
            title: 'Logistics',
            desc: 'Transportation and logistics fully arranged by TCWC',
        },
    ]

    return (
        <section className="postbanner">
            <img src="/assets/images/banner1.png" alt="postbanner img" />
            <div className="postForm ck-content">
                {props.location.pathname === '/selling_wine' ? (
                    <div className="syw-wrap-pst">
                        {sellYourWine.map((data, index) => (
                            <div
                                onClick={() => handleRedirectInternal(history, 'selling_wine')}
                                key={index}
                                className="slYrWnCard"
                            >
                                <div className="imgCotnr">
                                    <img src={data.img} alt={data.title} />
                                </div>
                                <div className="txtIfoCntr pl-3 pr-4">
                                    <h3>{data.title}</h3>
                                    <p>{data.desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}

                {viewStatic.content_type === 'file' ? (
                    <object>
                        <embed
                            src={`${global.site_url}/uploads/static_files/${viewStatic.file_path}`}
                            type="application/pdf"
                        ></embed>
                    </object>
                ) : (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: viewStatic.content,
                        }}
                    ></div>
                )}
            </div>
        </section>
    )
}

export default Static
