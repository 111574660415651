import React, { useEffect, useContext, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Loaders from '../custom/components/molecules/Loaders'

import commonContext from '../product/context/common/commonContext'
import AuthContext from '../product/context/auth/authContext'
import CustomCommonContext from '../custom/context/common/commonContext'
import ProductCommonContext from '../product/context/common/commonContext'

import Header from '../custom/components/molecules/Header'
import Footer from '../custom/components/molecules/Footer'
import ProductDetailedView from '../views/ProductDetailedView'
import Static from '../views/Static'
import PrivacyPolicy from '../views/Static/PrivacyPolicy'
import ContactUsStatic from '../views/Static/ContactUs'
// import MyWallet from '../views/Dashboard/MyWallet'
const Login = React.lazy(() => import('../views/Login'))

const ForgotPassword = React.lazy(() => import('../views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../views/ResetPassword'))

const Search = React.lazy(() => import('../views/Search'))
const MyAuctions = React.lazy(() => import('../views/Dashboard/MyAuctions'))
const Registration = React.lazy(() => import('../views/Registration'))
const IndividualRegistration = React.lazy(() =>
    import('../views/Registration/IndividualRegistration'),
)
const Profile = React.lazy(() => import('../views/Dashboard/Profile/index'))
const ChangePassword = React.lazy(() => import('../views/Dashboard/Profile/ChangePassword'))
const SalesOrder = React.lazy(() => import('../views/Dashboard/Invoices/SalesOrder'))
const InvoicePaid = React.lazy(() => import('../views/Dashboard/Invoices/Paid'))
const InvoiceUnPaid = React.lazy(() => import('../views/Dashboard/Invoices/UnPaid'))

const MyTransactions = React.lazy(() => import('../views/Dashboard/MyTransactions'))
const Invoice = React.lazy(() => import('../views/Invoice'))
const SellingWine = React.lazy(() => import('../views/Static/SellingWine'))

const LiveAuction = React.lazy(() => import('../views/ProductView/LiveAuction'))
const ProductViewInvite = React.lazy(() => import('../views/ProductView/ProductView_Invite'))
const Home = React.lazy(() => import('../views/Home'))
const Cart = React.lazy(() => import('../views/Cart'))
const BuynowCart = React.lazy(() => import('../views/BuyNowCart'))
const Checkout = React.lazy(() => import('../views/Checkout'))

const ErrorPage = React.lazy(() => import('../views/ErrorPage'))
const Auction = React.lazy(() => import('../views/Auction'))
const VerifyEmail = React.lazy(() => import('../views/VerifyEmail'))

const Notifications = React.lazy(() => import('../views/Dashboard/Notifications'))
const Producers = React.lazy(() => import('../views/Dashboard/Producers'))
const ContactUs = React.lazy(() => import('../views/ContactUs'))
const AboutUs = React.lazy(() => import('../views/Static/AboutUs'))
const SavedSearch = React.lazy(() => import('../views/Dashboard/SavedSearch'))
const RosoomResponse = React.lazy(() => import('../views/Rosoom/Response'))
const MyWinesPurchased = React.lazy(() => import('../views/Dashboard/MyWines/Purchased'))
const MyWinesSelling = React.lazy(() => import('../views/Dashboard/MyWines/Selling'))
const MyWinesSold = React.lazy(() => import('../views/Dashboard/MyWines/Sold'))

const SwitchUser = React.lazy(() => import('../views/SwitchUser'))

const HowItWorks = React.lazy(() => import('../views/Static/HowItWorks'))
const TermsAndConditions = React.lazy(() => import('../views/Static/TermsAndConidtions'))

const Routes = () => {
    const authContext = useContext(AuthContext)
    const { loadUser, callEnd } = authContext
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { getGlobalVariable } = customCommonContext
    const { getAllCountries, getAllStates } = productCommonContext
    const { loaderSet } = useContext(commonContext)

    useEffect(() => {
        loaderSet(true)
        if (localStorage.token) {
            loadUser(localStorage.token)
        } else {
            loaderSet(false)
        }

        getGlobalVariable()
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        if (callEnd) loaderSet(false)
    }, [callEnd])

    return (
        <>
            <Header />
            <Suspense fallback={<Loaders name="home" isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    <Route exact path="/registration" component={IndividualRegistration} />
                    {/* <Route
                        exact
                        path="/registration_individual"
                        component={IndividualRegistration}
                    /> */}
                    <Route exact path="/search" component={Search} />
                    <Route exact path="/liveAuction/:aid" component={LiveAuction} />
                    <Route exact path="/auction" component={Auction} />
                    <Route exact path="/productview/:pid" component={ProductDetailedView} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/sellingwine" component={SellingWine} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                    <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                    <PrivateRoute exact path="/cart" component={Cart} />
                    {/* <PrivateRoute exact path="/credit" component={MyWallet} /> */}
                    <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/watchlist/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/myWines/purchased" component={MyWinesPurchased} />
                    <PrivateRoute exact path="/myWines/selling" component={MyWinesSelling} />
                    <PrivateRoute exact path="/myWines/sold" component={MyWinesSold} />
                    <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/account/profile" component={Profile} />
                    <PrivateRoute exact path="/account/change" component={ChangePassword} />
                    <PrivateRoute exact path="/account/notifications" component={Notifications} />
                    <PrivateRoute exact path="/producers" component={Producers} />
                    <PrivateRoute exact path="/salesorder/all" component={SalesOrder} />
                    <PrivateRoute exact path="/invoices/paid" component={InvoicePaid} />
                    <PrivateRoute exact path="/invoices/unpaid" component={InvoiceUnPaid} />
                    <PrivateRoute exact path="/transactions" component={MyTransactions} />
                    <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                    <PrivateRoute exact path="/rosoom_success" component={RosoomResponse} />
                    <Route
                        exact
                        path={[
                            '/about',
                            '/services',
                            '/help',
                            '/guidelines',
                            '/privacy_policy',
                            '/terms_of_use',
                            '/faq',
                            '/delivery',
                            '/selling_wine',
                            '/contact_us',
                            '/header_first_page',
                            '/header_second_page',
                            '/header_third_page',
                            '/header_fourth_page',
                        ]}
                        component={Static}
                    />

                    <Route exact path="/switch" component={SwitchUser} />

                    <Route exact path="/aboutus" component={AboutUs} />
                    <Route exact path="/howitworks" component={HowItWorks} />
                    <Route exact path="/terms-conditions" component={TermsAndConditions} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/contact-us" component={ContactUsStatic} />

                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                <Footer />
            </Suspense>
            {/* <Suspense fallback={<Loaders name="home" />}> */}
            {/* </Suspense> */}
        </>
    )
}
export default withRouter(Routes)
