import React, { useReducer } from 'react'
import NorthCapitalContext from './northcapitalContext'
import NorthCapitalReducer from './northcapitalReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import {
    USER_LOADED,
    NORTH_CAPITAL_BANK_DETAILS,
    NORTH_CAPITAL_CARD_DETAILS,
    NORTH_CAPITAL_CARD_LINK,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    RESPONSE_STBIDS,
    RESPONSE_ASSET_TOTAL,
    PARTY_DOCUMENTS,
    PARTY_OWNER_DOCUMENTS,
} from './northcapitalTypes'

const NorthCapitalState = (props) => {
    const initialState = {
        responseStatus: null,
        northCapitalUser: null,
        north_capital_bank_details: null,
        north_capital_card_details: null,
        north_capital_card_link: null,
        stbids_list: [],
        asset_total: null,
        party_documents: null,
        party_owner_documents: null,
    }

    const [state, dispatch] = useReducer(NorthCapitalReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const onBoardIndividual = async (formData, noAlert = false) => {
        const from = 'onBoardIndividual'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateIndividual = async (formData, noAlert = false) => {
        const from = 'updateIndividual'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const onBoardEntity = async (formData, noAlert = false) => {
        const from = 'onBoardEntity'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const uploadPartyDocument = async (formData) => {
        const from = 'uploadPartyDocument'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const uploadEntityDocument = async (formData) => {
        const from = 'uploadEntityDocument'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const calculateSuitability = async (formData) => {
        const from = 'calculateSuitability'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const sendSubscriptionDocument = async (formData) => {
        const from = 'sendSubscriptionDocument'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const linkExternalAccount = async (formData, noAlert = false) => {
        const from = 'linkExternalAccount'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const updateLinkExternalAccount = async (formData) => {
        const from = 'updateLinkExternalAccount'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getUserDetails = async (formData) => {
        const from = 'getUserDetails'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: USER_LOADED,
                    payload: {
                        data: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getExternalAccount = async (formData) => {
        const from = 'getExternalAccount'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: NORTH_CAPITAL_BANK_DETAILS,
                    payload: {
                        data: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: NORTH_CAPITAL_BANK_DETAILS,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteExternalAccount = async (formData) => {
        const from = 'deleteExternalAccount'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getCreditCard = async (formData) => {
        const from = 'getCreditCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: NORTH_CAPITAL_CARD_DETAILS,
                    payload: {
                        data: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: NORTH_CAPITAL_CARD_DETAILS,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getLinkedCreditCard = async (formData) => {
        const from = 'getLinkedCreditCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            if (res.data.status === 'success') {
                console.log('res', res.data.data.responseData)
                await dispatch({
                    type: NORTH_CAPITAL_CARD_DETAILS,
                    payload: {
                        data: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: NORTH_CAPITAL_CARD_DETAILS,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const linkCreditCard = async (formData) => {
        const from = 'linkCreditCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])

            console.log('responseData', res.data.data.responseData.accountDetails)
            if (res.data.status === 'success') {
                await dispatch({
                    type: NORTH_CAPITAL_CARD_LINK,
                    payload: res.data.data.responseData.accountDetails,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addCreditCard = async (formData) => {
        const from = 'addCreditCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const updateCreditCard = async (formData) => {
        const from = 'updateCreditCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteCreditCard = async (formData) => {
        const from = 'deleteCreditCard'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteTrade = async (formData) => {
        const from = 'deleteTrade'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const addExternalBank = async (formData) => {
        const from = 'addExternalBank'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const updateExternalBank = async (formData) => {
        const from = 'updateExternalBank'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const addCampaignContact = async (formData, tag, noAlert = false, sendToZoho) => {
        const from = 'addCampaignContact'
        // user_type : [User,Subscriber] tag: [Registered,Bidding,Purchased]
        formData.sendToZoho = sendToZoho
        let payload =
            ['User', 'Subscriber'].indexOf(tag) > -1
                ? { ...formData, user_type: tag }
                : ['Registered', 'Bidding', 'Purchased'].indexOf(tag) > -1
                ? { ...formData, tag }
                : formData
        try {
            if (['User', 'Subscriber', 'Registered'].indexOf(tag) > -1)
                await Promise.all([
                    apiCall('post', 'addcontacts', payload, '', 'custom/index/campaign/zoho'),
                ])
            const [res] = await Promise.all([
                apiCall('post', 'subscribeCampaign', payload, '', 'custom/index/campaign/zoho'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const createOrder = async (formData) => {
        const from = 'createOrder'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateSettlement = async (formData) => {
        const from = 'updateSettlement'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getStbids = async (formData, from) => {
        from = from ? from : 'getStbids'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getStbids', formData, '', 'paymentThird/northcapital'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: RESPONSE_STBIDS,
                    payload: { ...res.data.data.responseData, from },
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: RESPONSE_STBIDS,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getStAssetTotal = async (formData) => {
        const from = 'getStAssetTotal'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type: RESPONSE_ASSET_TOTAL,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                await dispatch({
                    type: RESPONSE_ASSET_TOTAL,
                    payload: {
                        data: [],
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getuploadPartyDocument = async (formData, noAlert = false) => {
        const from = 'getuploadPartyDocument'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            if (res.data.status === 'success') {
                await dispatch({
                    type:
                        formData.isOwner && formData.isOwner === 1
                            ? PARTY_OWNER_DOCUMENTS
                            : PARTY_DOCUMENTS,
                    payload: {
                        data: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from, noAlert)
            }
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const linkEntityOwner = async (formData, noAlert = false) => {
        const from = 'linkEntityOwner'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const resendDocuSign = async (formData, noAlert = false) => {
        const from = 'resendDocuSign'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/northcapital'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    return (
        <NorthCapitalContext.Provider
            value={{
                responseStatus: state.responseStatus,
                northCapitalUser: state.northCapitalUser,
                north_capital_bank_details: state.north_capital_bank_details,
                north_capital_card_details: state.north_capital_card_details,
                stbids_list: state.stbids_list,
                asset_total: state.asset_total,
                party_documents: state.party_documents,
                party_owner_documents: state.party_owner_documents,
                north_capital_card_link: state.north_capital_card_link,
                linkCreditCard,
                getCreditCard,
                getLinkedCreditCard,
                onBoardIndividual,
                updateIndividual,
                onBoardEntity,
                uploadPartyDocument,
                uploadEntityDocument,
                getUserDetails,
                calculateSuitability,
                linkExternalAccount,
                updateLinkExternalAccount,
                getExternalAccount,
                deleteExternalAccount,
                addCreditCard,
                updateCreditCard,
                deleteCreditCard,
                clearResponse,
                addExternalBank,
                updateExternalBank,
                addCampaignContact,
                deleteTrade,
                createOrder,
                updateSettlement,
                getStbids,
                getStAssetTotal,
                getuploadPartyDocument,
                linkEntityOwner,
                resendDocuSign,
            }}
        >
            {props.children}
        </NorthCapitalContext.Provider>
    )
}

export default NorthCapitalState
