import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { COMMON_VALUES, RESPONSE_STATUS, CLEAR_RESPONSE, SEARCH_TEMP } from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allNotifications: [],
        allConfigurationVariables: {},
        wineColorOptions: [],
        wineLocation: [],
        wineVintageOptions: [],
        wineBottleSizeOptions: [],
        wineCountryOptions: [],
        categoryTypes: [],
        configFeatures: {},
        configVariables: {},
        allBidIncrements: [],
        allHomeBanner: [],
        responseStatus: null,
        languages: [],
        pharases: {},
        currentLanguage: 'for_english',
        searchTempValue: {},
    }

    const { i18n } = useTranslation()

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])
        if (res?.data?.status && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    wineColorOptions: res.data.data.responseData.tableValues.alldata_gvo_color,
                    wineLocation: res.data.data.responseData.tableValues.alldata_ctregion,
                    wineVintageOptions: res.data.data.responseData.tableValues.alldata_gvo_vintage,
                    wineBottleSizeOptions:
                        res.data.data.responseData.tableValues.alldata_bottlesize.sort((a, b) =>
                            a.show_order > b.show_order ? 1 : -1,
                        ),
                    wineCountryOptions: res.data.data.responseData.tableValues.alldata_gvo_country,
                    allHomeBanner: res.data.data.responseData.tableValues.allhome_banner.filter(
                        (value) => value.active === 1,
                    ),
                    categoryTypes: res.data.data.responseData.tableValues.allcategory_types,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    configFeatures: res.data.data.responseData.features,
                    configVariables: res.data.data.responseData.variables,
                    languages: res.data.data.responseData.languages,
                    phrases: res.data.data.responseData.phrases,
                },
            })
            console.log(res.data.data.responseData, 'responseData')
            //set reload the language phrase
            global.languages = res.data.data.responseData.languages
            global.phrases = res.data.data.responseData.phrases
            for (let i = 0; i < global.languages.length; i++) {
                i18n.addResourceBundle(
                    global.languages[i],
                    'translations',
                    global.phrases[global.languages[i]],
                    true,
                    true,
                )
            }
            i18n.changeLanguage('for_english')
        }
    }

    const setSearchTempValue = (data) =>
        dispatch({
            type: SEARCH_TEMP,
            payload: data,
        })

    const switchLanguage = (data) =>
        dispatch({
            type: SWITCH_LANGUAGE,
            payload: data,
        })
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CommonContext.Provider
            value={{
                allNotifications: state.allNotifications,
                allBidIncrements: state.allBidIncrements,
                allConfigurationVariables: state.allConfigurationVariables,
                wineColorOptions: state.wineColorOptions,
                allHomeBanner: state.allHomeBanner,
                wineLocation: state.wineLocation,
                wineVintageOptions: state.wineVintageOptions,
                wineBottleSizeOptions: state.wineBottleSizeOptions,
                wineCountryOptions: state.wineCountryOptions,
                categoryTypes: state.categoryTypes,
                configFeatures: state.configFeatures,
                configVariables: state.configVariables,
                languages: state.languages,
                currentLanguage: state.currentLanguage,
                searchTempValue: state.searchTempValue,
                responseStatus: state.responseStatus,
                switchLanguage,
                getGlobalVariable,
                setSearchTempValue,
                clearResponse,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
