import React, { useEffect, useContext, useState, useRef } from 'react'
import openSocket from 'socket.io-client'
import ProductContext from '../../product/context/product/productContext'
import AlertContext from '../../product/context/alert/alertContext'
import { messageHandler } from '../Common/socketHandler'
import AuthContext from '../../product/context/auth/authContext'
import Loaders from '../../custom/components/molecules/Loaders'
import ProductView from '../../custom/components/organisms/ProductView'
import '../../custom/components/organisms/ProductView/ProductView.css'

function ProductViewPage(props) {
    const productContext = useContext(ProductContext)
    const { user } = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { search_allproducts, getAllSearchProducts, responseStatus } = productContext
    const { setAlert } = alertContext

    let [viewProduct, setViewProduct] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    const [search, setSearch] = useState({
        limit: 1,
        page: 1,
        orderby: 'p.id, asc',
        market_status: ['closed', 'open', 'sold', 'unsold'],
        filters: {
            projectid: {
                value: '',
                type: 'in',
                field: 'p.id',
            },
        },
    })

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(global.site_url)

        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
            console.log(data, 'viewproduct1')
            //getAllSearchProducts(search)
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (search_allproducts.records.length) {
            setViewProduct(search_allproducts.records.length ? search_allproducts.records : [])
        }
        setIsLoading(false)
    }, [search_allproducts])
    /*useEffect(() => {
        if (Array.isArray(viewProduct)) {
            setViewProduct(viewProduct[0])
            console.log(viewProduct, 'viewProduct')
        }
    }, [viewProduct])*/

    useEffect(() => {
        if (search.filters.projectid.value) {
            setIsLoading(true)
            getAllSearchProducts(search)
        }
    }, [search])

    useEffect(() => {
        setSearch({
            ...search,
            filters: {
                ...search.filters,
                projectid: {
                    ...search.filters.projectid,
                    value: props.match.params.pid,
                },
            },
        })

        window.scrollTo(0, 0)
    }, [props.match.params.pid])
    // const productContext = useContext(ProductContext)
    // const [product, setProduct] = React.useState()
    // useEffect(() => {
    //     setProduct(props.data)
    // }, [props.data])

    return (
        <div className="productViewPage">
            {isLoading ? (
                <div className="customContainer">
                    <div className="noGridLoader">
                        <Loaders name="product_view" isLoading={isLoading} loop={1} />
                    </div>
                </div>
            ) : viewProduct ? (
                <ProductView
                    data={Array.isArray(viewProduct) ? viewProduct[0] : viewProduct}
                    standalone={true}
                    loading={true}
                />
            ) : null}
        </div>
    )
}

export default ProductViewPage
