import { autocomplete } from '@algolia/autocomplete-js'
import React, { createElement, Fragment, useEffect, useRef } from 'react'
import { render } from 'react-dom'

export function Autocomplete(props) {
    const containerRef = useRef(null)

    useEffect(() => {
        if (!containerRef.current) {
            return undefined
        }

        const search = autocomplete({
            container: containerRef.current,
            renderer: { createElement, Fragment, render },
            // onKeyDown: containerRef?.current?.setIsOpen(false),
            ...props,
        })
        // containerRef?.current?.onkeydown(() => search.setIsOpen(false))
        console.log(search, 'search.isOpen')
        return () => {
            search.setIsOpen(false)
            search.destroy()
        }
    }, [props])

    console.log(containerRef, 'containerRef')

    return <div className="customSearch" ref={containerRef} />
}
