import React from 'react'
import Skeletons from '../../../../custom/components/Skeletons'

function ProductViewSkeleton() {
    return (
        <div className="productViewSkeleton">
            <div className="pvLoaderInnerCnt">
                <div className="pvsImage">
                    <Skeletons type="gridImage" />
                    <div className="d-flex">
                        <Skeletons type="thumbnail" />
                        <Skeletons type="thumbnail" />
                        <Skeletons type="thumbnail" />
                    </div>
                </div>
                <div>
                    <Skeletons type="title" />
                    <Skeletons type="title" />
                    <Skeletons type="title" />
                    <Skeletons type="box" />
                    {/* <div className="pvsAct">
                        <Skeletons type="input" />
                        <Skeletons type="actionButton" />
                    </div> */}
                </div>
            </div>

            <div className="pvsInfo">
                <Skeletons type="text" />
                <Skeletons type="text" />
                <Skeletons type="text" />
                <Skeletons type="text" />
                <Skeletons type="text" />
                <Skeletons type="text" />
            </div>
        </div>
    )
}

export default ProductViewSkeleton
