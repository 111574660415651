import React, { useContext, useEffect, useState } from 'react'
import { handleRedirectInternal } from '../../../common/components'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import { useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import authContext from '../../../../product/context/auth/authContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import TertiaryButton from '../../atoms/TertiaryButton'
import CustomDialog from '../../organisms/Dialog'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import { mapData } from '../../../../product/common/components'
import BuynowContext from '../../../../product/context/buynow/buynowContext'

const Buynow = (props) => {
    const { isAuthenticated } = useContext(authContext)
    const { addToCart, responseStatus } = useContext(BuynowContext)
    const alertContext = useContext(AlertContext)

    const [product, setProduct] = useState()
    const location = useLocation()

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const { setAlert } = alertContext

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            formik.setFieldValue('product_id', product.id)
        }
    }, [product])

    const validationArray = Yup.object({
        product_id: Yup.number().required('Product ID required'),
        qty: Yup.number().required('Enter quantity'),
    })

    const formik = useFormik({
        initialValues: { product_id: 0, qty: 0 },
        validationSchema: validationArray,
        onSubmit: (values) => {
            let cart = []
            cart.push(values)
            console.log(values, 'checkValues')

            if (product.bid_count) {
                if (
                    parseInt(values.qty, 10) ===
                    parseInt(product.qty, 10) - parseInt(product.sold, 10)
                ) {
                    addToCart({ cart })
                } else {
                    setAlert(
                        `Minimum Quantity ${product ? product.qty - product.sold : 0}`,
                        'error',
                    )
                }
            } else {
                if (product.enforceminqtyinternally) {
                    if (values.qty >= parseInt(product.minimumquantity, 10)) {
                        addToCart({ cart })
                    } else {
                        setAlert(
                            `Minimum Quantity ${parseInt(product.minimumquantity, 10)}`,
                            'error',
                        )
                    }
                } else {
                    addToCart({ cart })
                }
            }

            // if (toggleDialog) {
            //     let cart = []
            //     cart.push(values)
            //     addToCart({ cart })
            // } else {
            //     setToggleDialog(true)
            // }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    setToggleDialog(false)
                    // if (props.successRedirect) {
                    //     setTimeout(() => {
                    //         // window.open('/buynowcart')
                    //         handleRedirectInternal(history, 'buynowcart')
                    //     }, 2000)
                    // }
                }
            }
        }
    }, [responseStatus])

    const buynowQuantity = [
        {
            label: 'Enter quantity',
            name: 'qty',
            type: 'number',
            placeholder: `${
                product && product.bid_count
                    ? `Min quantity ${product ? product.qty - product.sold : 0}`
                    : product?.custitem_gvo_invwebdisplay === 'Pre-arrival'
                    ? ''
                    : `Max quantity ${product ? product.qty - product.sold : 0}`
            }`,
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (product) {
            if (product.bid_count) {
                formik.values.qty = product.qty - product.sold > 0 ? product.qty - product.sold : 0
            } else {
                if (product.enforceminqtyinternally) {
                    formik.values.qty = parseInt(product.minimumquantity, 10)
                } else {
                    formik.values.qty = product.qty - product.sold > 0 ? 1 : 0
                }
            }
        }
    }, [product])

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt beforeLogin d-flex justify-content-between align-items-center">
                                {props.editQuantity && Object.values(mapData(buynowQuantity))}
                                {isAuthenticated ? (
                                    <PrimaryButton type="submit" btnSize="small" id="buynow_button">
                                        <span className="material-icons-outlined mr-2 mr-sm-3">
                                            shopping_cart
                                        </span>
                                        Buy now
                                    </PrimaryButton>
                                ) : (
                                    <PrimaryButton
                                        id="logintobuy_button"
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `login?pid=${product.id}`,
                                            )
                                        }
                                    >
                                        <span className="material-icons-outlined mr-2 mr-sm-3">
                                            shopping_cart
                                        </span>
                                        Login to buy
                                    </PrimaryButton>
                                )}
                            </div>
                        </form>
                    ) : null}
                </>
            ) : null}
            <CustomDialog
                title={'Confirm buy now'}
                open={toggleDialog}
                function={changeDialogStatus}
            >
                <h5>{'You are about to buy this product'}</h5>
                <h5>{'Please confirm if you want to continue with this action'}</h5>
                <div className="actionWrapper">
                    <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                    <PrimaryButton
                        onClick={formik.handleSubmit}
                        type="submit"
                        label={'Submit'}
                        id="confirmbuynow_button"
                    />
                </div>
            </CustomDialog>
        </>
    )
}

export default Buynow
