import React, { useReducer } from 'react'
import CreditContext from './creditContext'
import CreditReducer from './creditReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { CLEAR_RESPONSE, RESPONSE_STATUS, GET_ALL_USER_CREDIT } from './creditTypes'

const CreditState = (props) => {
    const initialState = {
        responseStatus: null,
        search_allusercredit: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
    }

    const [state, dispatch] = useReducer(CreditReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addCreditBalance = async (formData) => {
        const from = 'payCartWallet'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addCreditBalance', formData, '', 'credit'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addBidDeposit = async (formData, from = 'addBidDeposit') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addBidDeposit', formData, '', 'credit'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addGiftCardWalletBalance = async (formData, from = 'addGiftCardWalletBalance') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addGiftCardWalletBalance', formData, '', 'giftcard'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payCartWallet = async (formData) => {
        const from = 'payCartWallet'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'credit')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getCreditHistory = async (formData) => {
        const from = 'getCreditHistory'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'credit')])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_CREDIT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CreditContext.Provider
            value={{
                responseStatus: state.responseStatus,
                search_allusercredit: state.search_allusercredit,
                getCreditHistory,
                addBidDeposit,
                addGiftCardWalletBalance,
                payCartWallet,
                addCreditBalance,
                clearResponse,
            }}
        >
            {props.children}
        </CreditContext.Provider>
    )
}

export default CreditState
