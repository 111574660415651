import CategoryDropdown from './CategoryDropdown'
import React, { Component, useState, useEffect, useRef } from 'react'
import { Button, ListItem } from '@material-ui/core'

const CategoryItems = ({
    item,
    allCategories,
    depthLevel,
    onCountryClick,
    onRegionClick,
    onSubRegionClick,
}) => {
    const [dropdown, setDropdown] = useState(false)
    const sublist = allCategories.filter((cat) => cat.level_id == item.id)

    let ref = useRef()
    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false)
            }
        }
        document.addEventListener('mouseover', handler)
        document.addEventListener('mousedown', handler)
        document.addEventListener('touchstart', handler)
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler)
            document.removeEventListener('touchstart', handler)
        }
    }, [dropdown])

    return (
        <li ref={ref}>
            {sublist && sublist.length ? (
                <>
                    <ListItem
                        button
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={(e) =>
                            (item.level == 0 && item.id != 4) ||
                            (item.level == 1 && item.level_id == 4)
                                ? onCountryClick(item.value, e)
                                : item.level == 1
                                ? onRegionClick(item.value, e)
                                : onSubRegionClick(item.value, e)
                        }
                        className="pr-0 mr-0"
                        onMouseEnter={() => setDropdown(true)}
                    >
                        {item.description}{' '}
                        {depthLevel > 0 && (
                            <span className="material-icons text-right ml-auto">arrow_right</span>
                        )}
                        <CategoryDropdown
                            isVisible={dropdown}
                            submenus={sublist}
                            allCategories={allCategories}
                            depthLevel={depthLevel}
                            onCountryClick={onCountryClick}
                            onRegionClick={onRegionClick}
                            onSubregionClick={onSubRegionClick}
                        />
                    </ListItem>
                </>
            ) : (
                <ListItem
                    button
                    onClick={(e) =>
                        (item.level == 0 && item.id != 4) || (item.level == 1 && item.level_id == 4)
                            ? onCountryClick(item.value, e)
                            : item.level == 1
                            ? onRegionClick(item.value, e)
                            : onSubRegionClick(item.value, e)
                    }
                >
                    {' '}
                    {item.description}{' '}
                </ListItem>
            )}
        </li>
    )
}

export default CategoryItems
