import React, { useReducer } from 'react'
import axios from 'axios'
import CustomerContext from './customerContext'
import CustomerReducer from './customerReducer'
import { DirectAPICAll } from './../../../../common/components'
import { apiCall } from '../../../../common/api'
import { response } from '../../../common'
import { GET_CUSTOMER_DETAILS, RESPONSE_STATUS, CLEAR_RESPONSE } from './customerTypes'

const CyberSourceCustomerState = (props) => {
    const initialState = {
        customer_details: null,
        responseStatus: null,
    }
    const [state, dispatch] = useReducer(CustomerReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addCustomer = async (formData) => {
        const from = 'addCustomer'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'paymentThird/cybersource'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // const createCustomer = async (formData, noAlert) => {
    //     const from = 'createCustomer'

    //     try {
    //         const res = await DirectAPICAll(
    //             'post',
    //             `${process.env.REACT_APP_CYBERSOURCE_URL}/tms/v2/customers`,
    //             formData,
    //         )
    //         console.log('res createCustomer', res.data)
    //         resp.commonResponse(res.data, from, noAlert)
    //     } catch (err) {
    //         console.log('errr', err)
    //         resp.commonErrorResponse(from, noAlert)
    //     }
    // }

    const getCustomer = async (formData, noAlert) => {
        const from = 'retrieve'
        try {
            const res = await DirectAPICAll(
                'get',
                `${process.env.REACT_APP_CYBERSOURCE_URL}/tms/v2/customers/${formData.customerTokenId}`,
                null,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: res.data.data.responseData.record,
                })
            } else {
                resp.commonResponse(res.data, from, noAlert)
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }

    const updateCustomer = async (formData, noAlert) => {
        const from = 'update'
        try {
            const res = await DirectAPICAll(
                'patch',
                `${process.env.REACT_APP_CYBERSOURCE_URL}/tms/v2/customers/${formData.customerTokenId}`,
                formData,
            )
            resp.commonResponse(res.data, from)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CustomerContext.Provider
            value={{
                responseStatus: state.responseStatus,
                customer_details: state.customer_details,
                updateCustomer,
                addCustomer,
                getCustomer,
                clearResponse,
            }}
        >
            {props.children}
        </CustomerContext.Provider>
    )
}

export default CyberSourceCustomerState
