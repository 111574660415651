export const GET_ALL_INVOICE = 'GET_ALL_INVOICE'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_ALL_USER_INVOICES = 'GET_ALL_USER_INVOICES'
export const GET_ALL_USER_TRANSACTIONS = 'GET_ALL_USER_TRANSACTIONS'
export const GET_ALL_INVOICES = 'GET_ALL_INVOICES'
export const GET_SINGLE_INVOICE = 'GET_SINGLE_INVOICE'
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS'
export const GET_MULTIPLE_INVOICE = 'GET_MULTIPLE_INVOICE'
export const GET_VIEW_DOC = 'GET_VIEW_DOC'
