import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { socket } from './socket.js'
import {
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import CustomSelect from '../components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '../components/atoms/Inputs/CustomMultiSelect'
import RadioBox from '../components/atoms/RadioBox'

import MenuItem from '@material-ui/core/MenuItem'
import CustomInput from '../components/atoms/Inputs/CustomInput'
import CustomMaskInput from '../components/atoms/Inputs/CustomMaskInput'
import CustomMaskInputWithHidden from '../components/atoms/Inputs/CustomMaskInputWithHidden'
import GooglePlaceAutoComplete from '../components/atoms/Inputs/GooglePlaceAutoComplete'
import CustomPhone from '../components/atoms/Inputs/CustomPhone.js'
import PasswordStrength from './passwordStrength'
import './productCommon.css'
import './ckeditor.css'
import { TwitterPicker } from 'react-color'
import CheckBox from '../components/atoms/CheckBox'
import CustomTextArea from '../components/atoms/Inputs/CustomTextArea'
import CKEditor from 'ckeditor4-react'
import Uploader from '../../product/common/uploader'
import uploader from './ckeditor-uploader'

import FormHelperText from '@material-ui/core/FormHelperText'
import CustomAutocomplete from '../components/atoms/Inputs/CustomAutocomplete.js'
import { Button, Checkbox, Tooltip } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
// import { CKEditor } from '@ckeditor/ckeditor5-react'

import { CKEditor as CKEditor5 } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import MultipleArray from '../components/molecules/MultipleArrayPopup'
import OtpInput from 'react-otp-input'
import moment from 'moment'
import GoogleCaptch from '../components/atoms/GoogleRecaptcha/index.js'
import { Editor as TINYMCE } from '@tinymce/tinymce-react'
import tinyMCEImageUpload from './tinymce-uploader'
import SearchSelectComponent from '../components/atoms/Inputs/SearchSelectComponent.js'
import { format, isAfter, parse, parseISO } from 'date-fns'

let serverTime = new Date()
const monthFormat = 'YYYY-MM'
const yearFormat = 'YYYY'
let dateFormat = 'MM-DD-YYYY'
let dateTimeFormat = 'MM-DD-YYYY h:mm a'
const timeFormat = 'HH:mm A'

if (socket) {
    socket.on('servertime', (data) => {
        if (moment(data.dTime).isValid()) {
            serverTime = new Date(data.dTime)
        }
    })
}

const errorCheck = (data, formik) => {
    return data.filter &&
        formik &&
        formik.touched &&
        formik.touched.filters &&
        formik.touched.filters[data.name] &&
        formik.errors &&
        formik.errors.filters &&
        formik.errors.filters[data.name]
        ? formik.errors.filters[data.name]
        : formik.touched[data.name] && formik.errors[data.name]
}

//Multiselect
const multiSelectValue = (data, formik) => {
    return data?.filter
        ? formik.values.filters[data.name].value.length <= data.options.length
            ? data.options
                  .filter((d) =>
                      formik.values.filters[data.name].value.length
                          ? !formik.values.filters[data.name].value.includes(d.value)
                          : true,
                  )
                  .map((d) => d.value)
                  .flat()
            : (formik.values.filters[data.name].value.length = 0 && null)
        : formik.values[data.name].length <= data.options.length
        ? data.options
              .filter((d) =>
                  formik.values[data.name].length
                      ? !formik.values[data.name].includes(d.value)
                      : true,
              )
              .map((d) => d.value)
              .flat()
        : (formik.values[data.name].length = 0 && null)
}

const helperText = (data, formik) => {
    return data.filter &&
        formik &&
        formik.touched &&
        formik.touched.filters &&
        formik.touched.filters[data.name] &&
        formik.errors &&
        formik.errors.filters &&
        formik.errors.filters[data.name]
        ? formik.errors.filters[data.name]
        : formik.touched[data.name] && formik.errors[data.name]
}

//sorting
export const sortCategoryAlphabetical = (a, b) => {
    var nameA = a.description.toUpperCase() // ignore upper and lowercase
    var nameB = b.description.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // names must be equal
    return 0
}

export const sortDescendingCategoryAlphabetical = (a, b) => {
    var nameA = a.description.toUpperCase() // ignore upper and lowercase
    var nameB = b.description.toUpperCase() // ignore upper and lowercase
    if (nameA > nameB) {
        return -1
    }
    if (nameA < nameB) {
        return 1
    }

    // names must be equal
    return 0
}
// calculate age
export const compareDate = (fromDate) => {
    let compareDate = moment(moment(fromDate, dateFormat).format(dateFormat), dateFormat).toDate()
    let ageDifMs = new Date().getTime() - new Date(fromDate).getTime()
    return Math.sign(ageDifMs) >= 1 ? true : false // miliseconds from epoch
}

// calculate age
export const calculateAge = (birthday) => {
    let compareDate = moment(moment(birthday, dateFormat).format(dateFormat), dateFormat).toDate()
    let ageDifMs = Date.now() - compareDate.getTime()
    let ageDate = new Date(ageDifMs) // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}
//calculatePercentage
export const calculatePercentage = (amount, percent) => {
    let percentAmount = 0
    let totalAmount = 0

    percentAmount = parseFloat(parseFloat(amount) * parseFloat(percent / 100)).toFixed(2)
    totalAmount = parseFloat(parseFloat(amount) + parseFloat(percentAmount)).toFixed(2)

    return { percentAmount, totalAmount }
}
//convert into datetime format
export const converDateTime = (data) => {
    let dataReturn = null
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateTimeFormat)}`
        }
    }
    return dataReturn
}

function shouldDisableDate(date, data) {
    if (data.shouldDisableDate && data.shouldDisableDate.length) {
        let allDates = data.shouldDisableDate
        if (allDates.find((e) => e == date.format('YYYY-MM-DD'))) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

// accepts array dates for data.shouldEnableDate in format 'YYYY-MM-DD'
function shouldEnableDate(date, data) {
    let allDates = data.shouldEnableDate
    if (allDates?.length > 0) {
        return allDates.find((e) => e == date.format('YYYY-MM-DD')) == undefined
    } else {
        return true
    }
}

export const urlFormat = (data) => {
    return data ? data.toLowerCase().replaceAll(' ', '_').replaceAll('/', '-') : ''
}

export const converDate = (data, customDateFormat) => {
    let dataReturn = ''
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(customDateFormat || dateFormat)}`
        }
    }
    return dataReturn
}

export const converYear = (data) => {
    let dataReturn = ''
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(yearFormat)}`
        }
    }
    return dataReturn
}

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array)
    }
}

export const getCountryCode = (allCountries, countryName) => {
    let dataReturn = allCountries.filter((inner) => inner.name === countryName)
    if (dataReturn.length) {
        dataReturn = dataReturn[0]
    } else {
        dataReturn = ''
    }

    return dataReturn
}

export const getStateCode = (allStates, stateName) => {
    let dataReturn = allStates.filter((inner) => inner.name === stateName)
    if (dataReturn.length) {
        dataReturn = dataReturn[0]
    } else {
        dataReturn = ''
    }

    return dataReturn
}

function defaultTransulation(value) {
    return value
}

//All form fields are created based on the data.type
export const mapData = (page, multiform, step, t = defaultTransulation) => {
    let formik = page.formik ? page.formik : page[0].formik
    let pageData
    if (multiform) {
        pageData = page.data[step]
    } else {
        pageData = page.data ? page.data : page
    }
    dateFormat = global.dateFormat ? global.dateFormat : 'MM-DD-YYYY'
    dateTimeFormat = global.defaultDateTimeFormat
        ? global.defaultDateTimeFormat
        : 'MM-DD-YYYY h:mm a'
    let data = pageData.map((data, index) => (
        <>
            <div key={index} className={data.class}>
                {data.type === 'select' ? (
                    <>
                        <CustomSelect
                            label={data.label}
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            variant={data.variant}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            size={data.size}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            placeholder={data.placeholder}
                            disabled={data.disabled}
                            onBlur={formik.handleBlur}
                            type={data.type}
                            error={errorCheck(data, formik)}
                            helperText={helperText(data, formik)}
                            native={data.native}
                            options={data.options}
                            groupoptions={data.groupoptions}
                            upperLabel={data.upperLabel}
                            shrink={data.shrink}
                            customizedDropdown={data.customizedDropdown || false}
                            className={data.native ? '' : 'nonNative'}
                        >
                            {!data.isNoDefaultOptionValue && (
                                <option value="" name={data.placeholder}>
                                    {data.placeholder}
                                </option>
                            )}
                            {data.options &&
                                data.options.map((opt, optindex) => (
                                    <option
                                        key={optindex}
                                        value={opt.value}
                                        name={opt.value}
                                        disabled={opt.disabled ? opt.disabled : false}
                                    >
                                        {opt.show}
                                    </option>
                                ))}

                            {data.groupoptions &&
                                data.groupoptions.map((opt, optindex) => (
                                    <>
                                        <optgroup label={opt.head}>
                                            <>
                                                {opt.options.map((opt, optindex) => (
                                                    <option
                                                        key={optindex}
                                                        value={opt.value}
                                                        name={opt.value}
                                                    >
                                                        {opt.show}
                                                    </option>
                                                ))}
                                            </>
                                        </optgroup>
                                    </>
                                ))}
                        </CustomSelect>
                    </>
                ) : data.type === 'autocomplete' ? (
                    <>
                        <CustomAutocomplete
                            label={data.label}
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            size={data.size}
                            onChange={(val) => {
                                data.filter
                                    ? formik.setFieldValue(`filters.${data.name}.value`, val)
                                    : formik.setFieldValue(data.name, val)
                            }}
                            placeholder={data.placeholder}
                            disabled={data.disabled}
                            onBlur={formik.handleBlur}
                            options={data.options}
                            type={data.type}
                            error={errorCheck(data, formik)}
                            helperText={helperText(data, formik)}
                        />
                    </>
                ) : data.type === 'searchdrop' ? (
                    <>
                        <SearchSelectComponent
                            label={data.label}
                            id={data.id}
                            multiple={data.multiple}
                            onClear={data.onClear}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            forcePopupIcon={data?.forcePopupIcon}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            size={data.size}
                            onChange={(val) => {
                                data.filter
                                    ? formik.setFieldValue(`filters.${data.name}.value`, val)
                                    : formik.setFieldValue(data.name, val)
                            }}
                            placeholder={data.placeholder}
                            disabled={data.disabled}
                            onBlur={formik.handleBlur}
                            options={data.options}
                            getAllOptions={data.getAllOptions}
                            type={data.type}
                            error={errorCheck(data, formik)}
                            helperText={helperText(data, formik)}
                            focused={data?.focused}
                        />
                    </>
                ) : data.type === 'misc' ? (
                    <>{data.content}</>
                ) : data.type === 'colorpicker' ? (
                    <>
                        {data?.label ? (
                            <div className="datePickerLabelCnt d-flex justify-content-between w-100">
                                <label className="datePickerLabel text-left">{data.label}</label>
                                <Button
                                    onClick={() => formik.setFieldValue(data.name, '')}
                                    variant="outlined"
                                >
                                    <span className="material-icons">clear</span>
                                    Clear
                                </Button>
                            </div>
                        ) : null}
                        <TwitterPicker
                            color={formik.values[data.name]}
                            onChangeComplete={(color) => formik.setFieldValue(data.name, color.hex)}
                        />
                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'switch' ? (
                    <>
                        <FormGroup row className="customToggle">
                            <FormControlLabel
                                label={data.label}
                                control={
                                    <Switch
                                        checked={formik.values[data.name]}
                                        onChange={formik.handleChange}
                                        name={data.name}
                                        color={data.color}
                                    />
                                }
                            />
                        </FormGroup>
                    </>
                ) : data.type === 'check' ? (
                    <>
                        {data.options &&
                            data.options.map((opt, optindex) => (
                                <CheckBox
                                    key={optindex}
                                    name={data.filter ? `filters.${data.name}.value` : data.name}
                                    disabled={data.disabled}
                                    label={opt.description}
                                    customized={opt.customized}
                                    checked={
                                        data.filter
                                            ? formik?.values?.filters[data.name]?.value
                                                  ?.flat()
                                                  .indexOf(opt.id.toString()) > -1
                                            : formik?.values[data.name]
                                                  ?.flat()
                                                  .indexOf(opt.id.toString()) > -1
                                    }
                                    value={opt.id.toString()}
                                    onChange={data.onChange ? data.onChange : formik.handleChange}
                                />
                            ))}
                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'uploadDropZone' ? (
                    <>
                        <Uploader
                            formik={formik}
                            name={data.name}
                            icon={data.icon}
                            titleText={data.titleText}
                            innerText={data.innerText}
                            folder={data.folder}
                            multiple={data.multiple}
                            accept={data.accept}
                            reorder={data.reorder}
                            objectImage={data.object_image}
                            isCompress={data.is_compress ? true : false}
                            quality={data.compress_quality}
                            resultWidth={data.compress_width}
                            resultHeight={data.compress_height}
                            customized_uploader={data.customized_uploader}
                            disableEditing={data.disableEditing}
                            disabled={data?.disabled}
                            webcam={data.webcam}
                            getBlobUrl={data.getBlobUrl}
                            avatarMode={data.avatarMode}
                        ></Uploader>
                    </>
                ) : data.type === 'tinymce' ? (
                    <>
                        {data?.label ? (
                            <label className="datePickerLabel text-left w-100">{data.label}</label>
                        ) : null}
                        <TINYMCE
                            //initialValue={formik.values[data.name]}
                            value={formik.values[data.name]}
                            //apiKey="npza6iqlfcih82txxmivvlbj7ra000zmh9ccmlul0vvv5sul" - live
                            apiKey={process.env.REACT_APP_TINYMCEKEY}
                            init={{
                                menubar: true,
                                plugins: [
                                    'print',
                                    'preview',
                                    'powerpaste',
                                    'casechange',
                                    'importcss',
                                    'searchplace',
                                    'autolink',
                                    'directonaity',
                                    'advcode',
                                    'visualblock',
                                    'visualchars',
                                    'fullscreen',
                                    'image',
                                    'link',
                                    'media',
                                    'mediaembed',
                                    //'template',
                                    'codesample',
                                    'table',
                                    'charmap',
                                    'hr',
                                    'pagebreak',
                                    'nonbreaking',
                                    'anchor',
                                    'toc',
                                    'insertdatetime',
                                    'advist',
                                    'lists',
                                    'checklists',
                                    'wordcount',
                                    //'tinymcespellchecker',
                                    'a11ychecker',
                                    'imagetools',
                                    'textpattern',
                                    'noneditable',
                                    'help',
                                    'formatpainter',
                                    'permanentpen',
                                    'pageembed',
                                    //'tinycomments',
                                    //'mentions',
                                    'quickbar',
                                    'linkchecker',
                                    'emoticons',
                                    'advtable',
                                    'export',
                                    'code',
                                ],
                                menubar: 'file edit view insert format tools table tc help',
                                toolbar:
                                    'undo redo | bold italic underline strikethrough | fontselect insert styleselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor casechange permanentpen removeformat| pagebreak | charmap emoticons | fullscreen preview print | insertfile image media pageembed link anchor codesample | a11ycheck ltr rtl',
                                // | insert | styleselect | casechange blocks | bold italic forecolor backcolor | bullist numlist checklist outdent indent | removeformat | allycheck code table | link image code',
                                quickbars_selection_toolbar:
                                    'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                toolbar_mode: 'sliding',
                                spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
                                content_style: '',
                                contextmenu: 'link image imagetools table configurepermanentpen',
                                content_style: '',
                                powerpaste_allow_local_images: true,
                                powerpaste_word_import: 'prompt',
                                powerpaste_html_import: 'prompt',
                                images_upload_handler: tinyMCEImageUpload,
                            }}
                            onEditorChange={(event, editor) => {
                                data.onChange
                                    ? data.onChange
                                    : formik.setFieldValue(data.name, editor.getContent() || ' ')
                            }}
                        />

                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'phone' ? (
                    <>
                        <CustomPhone
                            id={data.id}
                            value={formik.values[data.name]}
                            autoFocus={data.autoFocus}
                            name={data.name}
                            displayFlag={data.displayFlag}
                            disableAutocomplete={data.disableAutocomplete}
                            disabled={data.disabled}
                            shrink={formik.values[data.name] && true}
                            onBlur={formik.handleBlur}
                            onChange={(phone) => formik.setFieldValue(data.name, phone)}
                            label={data.label}
                            placeholder={data.placeholder}
                            tooltiptitle={data?.tooltiptitle}
                            endAdornment={data?.endAdornment}
                            type={data.type}
                            onError={data.onError}
                            minChar={data.minChar}
                            maxChar={data.maxChar}
                            error={errorCheck(data, formik)}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            countryCodeEditable={data.countryCodeEditable}
                            upperLabel={data.upperLabel}
                            variant={data.variant}
                        />
                    </>
                ) : data.type === 'multiselect' ? (
                    <>
                        <CustomMultiSelect
                            label={data.label}
                            id={data.id}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            onChange={(event, value) => {
                                let arrayValue = event.target.value.flat()
                                let allLength = data.filter
                                    ? formik.values.filters[data.name].value.length ===
                                      data.options.length
                                    : formik.values[data.name].length === data.options.length

                                if (allLength && arrayValue.length === data.options.length) {
                                    arrayValue = []
                                }
                                if (data.onChange) {
                                    data.onChange(event, arrayValue)
                                } else {
                                    data.filter
                                        ? formik.setFieldValue(
                                              `filters.${data.name}.value`,
                                              arrayValue,
                                          )
                                        : formik.setFieldValue(data.name, arrayValue)
                                }
                            }}
                            placeholder={data.placeholder}
                            onBlur={formik.handleBlur}
                            disabled={data.disabled}
                            type={data.type}
                            error={errorCheck(data, formik)}
                            helperText={helperText(data, formik)}
                            options={data.options}
                            size={data.size}
                            upperLabel={data.upperLabel}
                            onlyShowLable={data.onlyShowLable}
                        >
                            <MenuItem value={multiSelectValue(data, formik)}>
                                <Checkbox
                                    checked={
                                        data.filter
                                            ? formik.values.filters[data.name].value?.flat()
                                                  .length == data.options.length
                                            : formik.values[data.name]?.flat().length ==
                                              data.options.length
                                    }
                                    onChange={(val) => {
                                        if (data.onChange) {
                                            // const checked = val.target.checked
                                            // let newValue = []
                                            // if (checked) {
                                            //     newValue = data.filter
                                            //         ? formik.values.filters[data.name].value.concat(
                                            //               multiSelectValue(data, formik),
                                            //           )
                                            //         : formik.values[data.name].concat(
                                            //               multiSelectValue(data, formik),
                                            //           )
                                            // } else {
                                            //     newValue = []
                                            // }
                                            // data.onChange(val, newValue)
                                        } else {
                                            val.target.checked
                                                ? data.filter
                                                    ? formik.setFieldValue(
                                                          `filters.${data.name}.value`,
                                                          formik.values.filters[
                                                              data.name
                                                          ].value.concat(
                                                              multiSelectValue(data, formik),
                                                          ),
                                                      )
                                                    : formik.setFieldValue(
                                                          data.name,
                                                          formik.values[data.name].concat(
                                                              multiSelectValue(data, formik),
                                                          ),
                                                      )
                                                : data.filter
                                                ? formik.setFieldValue(
                                                      `filters.${data.name}.value`,
                                                      [],
                                                  )
                                                : formik.setFieldValue(data.name, [])
                                        }
                                    }}
                                />
                                <ListItemText primary={t('All')} />
                            </MenuItem>
                            {data.options.map((opt, optindex) => (
                                <MenuItem key={optindex} value={opt.value}>
                                    <Checkbox
                                        checked={
                                            data.filter
                                                ? formik?.values?.filters[data.name]?.value
                                                      ?.flat()
                                                      .indexOf(opt.value) > -1
                                                : formik?.values[data.name]
                                                      ?.flat()
                                                      .indexOf(opt.value) > -1
                                        }
                                        onChange={(val) => {
                                            if (data.onChange) {
                                                // do something
                                            } else {
                                                formik.handleChange(val)
                                            }
                                        }}
                                        // onChange={
                                        //     data.onChange ? data.onChange : formik.handleChange
                                        // }
                                    />
                                    {opt.show}
                                </MenuItem>
                            ))}
                        </CustomMultiSelect>
                    </>
                ) : data.type === 'ckeditor5' ? (
                    <>
                        {data?.label ? (
                            <label
                                className={`${
                                    data.disabled ? 'datePickerLabels' : 'datePickerLabel'
                                } text-left w-100`}
                            >
                                {data.label}
                            </label>
                        ) : null}
                        <CKEditor5
                            editor={ClassicEditor}
                            config={
                                data.config
                                    ? data.config
                                    : {
                                          htmlSupport: {
                                              allow: [
                                                  {
                                                      name: /.*/,
                                                      attributes: true,
                                                      classes: true,
                                                      styles: true,
                                                  },
                                              ],
                                          },
                                      }
                            }
                            onReady={(editor) => {
                                if (editor)
                                    editor.plugins.get('FileRepository').createUploadAdapter = (
                                        loader,
                                    ) => new uploader(loader)
                                return editor
                            }}
                            disabled={data.disabled}
                            data={formik.values[data.name]}
                            onChange={(event, editor) => {
                                formik.setFieldValue(data.name, editor.getData() || ' ')
                            }}
                        />

                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'ckeditor' ? (
                    <>
                        {/* <CKEditor
                        editor={ClassicEditor}
                        data={formik.values[data.name] ? formik.values[data.name] : ''}
                        onChange={(event, editor) => {
                            formik.setFieldValue(data.name, editor.getData())
                        }}
                    /> */}
                        {data?.label ? (
                            <label className="datePickerLabel text-left w-100">{data.label}</label>
                        ) : null}
                        <CKEditor
                            config={{
                                allowedContent: true,
                            }}
                            type="classic"
                            data={formik.values[data.name]}
                            onChange={(event, editor) => {
                                formik.setFieldValue(data.name, event.editor.getData())
                            }}
                        />
                        <div className="checkboxError">
                            <FormHelperText>
                                {formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]}
                            </FormHelperText>
                        </div>
                    </>
                ) : data.type === 'password_checker' ? (
                    <>
                        <PasswordStrength
                            data={data}
                            field={data.field ? data.field : 'password'}
                        />
                    </>
                ) : data.type === 'date' ? (
                    data.upperLabel ? (
                        <>
                            <label className="datePickerLabel">{data.label}</label>
                            <KeyboardDatePicker
                                margin="0"
                                autoOk={true}
                                showTodayButton={true}
                                id={data.name}
                                name={data.name}
                                inputVariant="outlined"
                                format={data?.customDateFormat || dateFormat}
                                placeholder={data.placeholder || ''}
                                disabled={data.disabled}
                                disableFuture={data.disableFuture || false}
                                disablePast={data.disablePast || false}
                                maxDate={data.maxDate || moment('01/01/2100')}
                                maxDateMessage={
                                    data.maxDateMessage || 'Date should not be after maximal date'
                                }
                                minDate={data.minDate || moment('01/01/1900')}
                                minDateMessage={
                                    data.minDateMessage || 'Date should not be before minimal date'
                                }
                                className="customDatepicker"
                                value={
                                    data.filter
                                        ? formik.values.filters[data.name].value || null
                                        : formik.values[data.name] || null
                                }
                                inputValue={
                                    data.filter
                                        ? formik.values.filters[data.name].value || null
                                        : formik.values[data.name] || null
                                }
                                onChange={(val) => {
                                    data.filter
                                        ? formik.setFieldValue(
                                              `filters.${data.name}.value`,
                                              converDate(val, data?.customDateFormat),
                                          )
                                        : formik.setFieldValue(
                                              data.name,
                                              converDate(val, data?.customDateFormat),
                                          )
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={formik.touched[data.name] && formik.errors[data.name]}
                                helperText={
                                    helperText(data, formik)
                                        ? helperText(data, formik)
                                        : data.helperText
                                }
                                size={data.size}
                            />
                        </>
                    ) : (
                        <>
                            <KeyboardDatePicker
                                margin="0"
                                autoOk={true}
                                showTodayButton={true}
                                id={data.name}
                                name={data.name}
                                label={data.label}
                                inputVariant="outlined"
                                format={data?.customDateFormat || dateFormat}
                                placeholder={data.placeholder || ''}
                                disabled={data.disabled}
                                disableFuture={data.disableFuture || false}
                                disablePast={data.disablePast || false}
                                maxDate={data.maxDate || moment('01/01/2100')}
                                maxDateMessage={
                                    data.maxDateMessage || 'Date should not be after maximal date'
                                }
                                minDate={data.minDate || moment('01/01/1900')}
                                minDateMessage={
                                    data.minDateMessage || 'Date should not be before minimal date'
                                }
                                className="customDatepicker"
                                value={
                                    data.filter
                                        ? formik.values.filters[data.name].value || null
                                        : formik.values[data.name] || null
                                }
                                inputValue={
                                    data.filter
                                        ? formik.values.filters[data.name].value || null
                                        : formik.values[data.name] || null
                                }
                                onChange={(val) => {
                                    data.filter
                                        ? formik.setFieldValue(
                                              `filters.${data.name}.value`,
                                              converDate(val, data?.customDateFormat),
                                          )
                                        : formik.setFieldValue(
                                              data.name,
                                              converDate(val, data?.customDateFormat),
                                          )
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={formik.touched[data.name] && formik.errors[data.name]}
                                helperText={
                                    helperText(data, formik)
                                        ? helperText(data, formik)
                                        : data.helperText
                                }
                                size={data.size}
                            />
                        </>
                    )
                ) : data.type === 'dateWithCustomPicker' ? (
                    <>
                        <KeyboardDatePicker
                            margin="0"
                            autoOk={true}
                            showTodayButton={
                                typeof data.showTodayButton === 'boolean'
                                    ? data.showTodayButton
                                    : true
                            }
                            id={data.name}
                            name={data.name}
                            open={data.open}
                            onOpen={data.onOpen ? data.onOpen : null}
                            onClose={data.onClose ? data.onClose : null}
                            inputVariant="outlined"
                            label={data.label}
                            format={dateFormat}
                            disabled={data.disabled}
                            disableFuture={data.disableFuture || false}
                            disablePast={data.disablePast || false}
                            maxDate={data.maxDate || moment('2100-01-01').format('YYYY-MM-DD')}
                            maxDateMessage={
                                data.maxDateMessage || 'Date should not be after maximal date'
                            }
                            minDate={data.minDate || moment('1900-01-01').format('YYYY-MM-DD')}
                            minDateMessage={
                                data.minDateMessage || 'Date should not be before minimal date'
                            }
                            className="customDatepicker"
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            inputValue={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            onChange={(val) => {
                                data.filter
                                    ? formik.setFieldValue(
                                          `filters.${data.name}.value`,
                                          converDate(val),
                                      )
                                    : formik.setFieldValue(data.name, converDate(val))
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            size={data.size}
                            shouldDisableDate={(date) => {
                                return data.shouldDisableDate
                                    ? shouldDisableDate(date, data)
                                    : data.shouldEnableDate
                                    ? shouldEnableDate(date, data)
                                    : false
                            }}
                        />
                    </>
                ) : data.type === 'month' ? (
                    <>
                        <KeyboardDatePicker
                            margin="0"
                            autoOk={true}
                            openTo="year"
                            views={['year', 'month']}
                            showTodayButton={true}
                            id={data.name}
                            name={data.name}
                            inputVariant="outlined"
                            label={data.label}
                            disablePast={data.disablePast || false}
                            maxDate={data.maxDate || moment('01/01/2100')}
                            maxDateMessage={
                                data.maxDateMessage || 'Date should not be after maximal date'
                            }
                            minDate={data.minDate || moment('01/01/1900')}
                            minDateMessage={
                                data.minDateMessage || 'Date should not be before minimal date'
                            }
                            format={monthFormat}
                            disabled={data.disabled}
                            className="customDatepicker"
                            value={formik.values[data.name]}
                            inputValue={formik.values[data.name]}
                            onChange={(val) => {
                                formik.setFieldValue(data.name, moment(val).format(monthFormat))
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                        />
                    </>
                ) : data.type === 'year' ? (
                    <>
                        <KeyboardDatePicker
                            margin="0"
                            autoOk={true}
                            showTodayButton={true}
                            id={data.name}
                            openTo="year"
                            views={['year']}
                            name={data.name}
                            label={data.label}
                            inputVariant="outlined"
                            format={yearFormat}
                            placeholder={data.placeholder || ''}
                            disabled={data.disabled}
                            disableFuture={data.disableFuture || false}
                            disablePast={data.disablePast || false}
                            maxDate={data.maxDate || moment('01/01/2100')}
                            maxDateMessage={
                                data.maxDateMessage || 'Date should not be after maximal date'
                            }
                            minDate={data.minDate || moment('01/01/1900')}
                            minDateMessage={
                                data.minDateMessage || 'Date should not be before minimal date'
                            }
                            className="customDatepicker"
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            inputValue={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            onChange={(val) => {
                                data.filter
                                    ? formik.setFieldValue(
                                          `filters.${data.name}.value`,
                                          converYear(val),
                                      )
                                    : formik.setFieldValue(data.name, converYear(val))
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            size={data.size}
                        />
                    </>
                ) : data.type === 'datetime' ? (
                    data.upperLabel ? (
                        <>
                            <label className="datePickerLabel">{data.label}</label>
                            <KeyboardDateTimePicker
                                margin="0"
                                autoOk={true}
                                ampm={global.timeFormat == '24h' ? false : true}
                                showTodayButton={
                                    typeof data.showTodayButton === 'boolean'
                                        ? data.showTodayButton
                                        : true
                                }
                                id={data.name}
                                name={data.name}
                                inputVariant="outlined"
                                // label={data.label}
                                format={dateTimeFormat}
                                disabled={data.disabled}
                                disablePast={data.disablePast || false}
                                maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
                                maxDateMessage={
                                    data.maxDateMessage || 'Date should not be after maximal date'
                                }
                                minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
                                minDateMessage={
                                    data.minDateMessage || 'Date should not be before minimal date'
                                }
                                className="customDatepicker"
                                value={formik.values[data.name]}
                                inputValue={formik.values[data.name]}
                                onChange={(val) => {
                                    formik.setFieldValue(data.name, converDateTime(val))
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={formik.touched[data.name] && formik.errors[data.name]}
                                helperText={
                                    formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]
                                }
                            />
                        </>
                    ) : (
                        <>
                            <KeyboardDateTimePicker
                                margin="0"
                                autoOk={true}
                                ampm={global.timeFormat == '24h' ? false : true}
                                showTodayButton={
                                    typeof data.showTodayButton === 'boolean'
                                        ? data.showTodayButton
                                        : true
                                }
                                id={data.name}
                                name={data.name}
                                inputVariant="outlined"
                                label={data.label}
                                format={dateTimeFormat}
                                disabled={data.disabled}
                                disablePast={data.disablePast || false}
                                maxDate={data.maxDate || moment('01-01-2100').format(dateFormat)}
                                maxDateMessage={
                                    data.maxDateMessage || 'Date should not be after maximal date'
                                }
                                minDate={data.minDate || moment('01-01-1900').format(dateFormat)}
                                minDateMessage={
                                    data.minDateMessage || 'Date should not be before minimal date'
                                }
                                className="customDatepicker"
                                value={formik.values[data.name]}
                                inputValue={formik.values[data.name]}
                                onChange={(val) => {
                                    data.filter || data.table_search
                                        ? formik.setFieldValue(
                                              `filters.${data.name}.value`,
                                              converDateTime(val),
                                          )
                                        : formik.setFieldValue(data.name, converDateTime(val))
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                error={formik.touched[data.name] && formik.errors[data.name]}
                                helperText={
                                    formik.errors[data.name] &&
                                    formik.touched[data.name] &&
                                    formik.errors[data.name]
                                }
                                InputAdornmentProps={{ position: data.icon_only ? 'start' : 'end' }}
                                InputProps={{
                                    readOnly: data.icon_only ? true : false,
                                }}
                            />
                        </>
                    )
                ) : data.type === 'time' ? (
                    <>
                        <KeyboardTimePicker
                            margin="0"
                            autoOk={true}
                            id={data.name}
                            name={data.name}
                            inputVariant="outlined"
                            label={data.label}
                            disabled={data.disabled}
                            disablePast={data.disablePast || false}
                            className="customDatepicker"
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value || null
                                    : formik.values[data.name] || null
                            }
                            inputValue={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                        ? moment(formik.values.filters[data.name].value, [
                                              'HH:mm',
                                          ]).format('hh:mm A')
                                        : null || null
                                    : formik.values[data.name]
                                    ? moment(formik.values[data.name], ['HH:mm']).format('hh:mm A')
                                    : null || null
                            }
                            onChange={(val) => {
                                data.filter || data.table_search
                                    ? formik.setFieldValue(
                                          `filters.${data.name}.value`,
                                          moment(val).format(timeFormat),
                                      )
                                    : formik.setFieldValue(
                                          data.name,
                                          moment(val).format(timeFormat),
                                      )
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                        />
                    </>
                ) : data.type === 'textarea' ? (
                    <>
                        <CustomTextArea
                            id={data.id}
                            value={formik.values[data.name]}
                            autoFocus={data.autoFocus}
                            name={data.name}
                            disabled={data.disabled}
                            shrink={formik.values[data.name] && true}
                            onBlur={formik.handleBlur}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            upperLabel={data.upperLabel}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                        />
                    </>
                ) : data.type === 'checkbox' ? (
                    <>
                        <CheckBox
                            checked={formik.values[data.name] ? true : false}
                            label={data.label}
                            value={true}
                            customized={data.customized}
                            disabled={data.disabled || false}
                            onChange={() => {
                                formik.setFieldValue(data.name, !formik.values[data.name])
                            }}
                            name={data.name}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                        />
                    </>
                ) : data.type === 'checkboxarray' ? (
                    data.item.map((d, i) => (
                        <>
                            <CheckBox
                                name={data.name}
                                label={d.description}
                                checked={
                                    formik.values[data.name].indexOf(d.id.toString()) !== -1
                                        ? true
                                        : false
                                }
                                disabled={d.disabled || false}
                                value={d.id.toString()}
                                onChange={formik.handleChange}
                            />
                        </>
                    ))
                ) : data.type === 'google_place_autocomplete' ? (
                    <>
                        <GooglePlaceAutoComplete
                            id={data.id}
                            name={data.name}
                            value={formik.values[data.name]}
                            formik={formik}
                            label={data.label}
                            googlePlaceType={data.googlePlaceType}
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            placeID={data.placeID}
                            fullAddress={data.fullAddress}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                        />
                    </>
                ) : data.type === 'radio' ? (
                    <>
                        <RadioBox
                            error={formik.touched[data.name] && formik.errors[data.name]}
                            helperText={
                                formik.errors[data.name] &&
                                formik.touched[data.name] &&
                                formik.errors[data.name]
                            }
                            title={data.hideTitle ? '' : data.title || data.label}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            items={data.item || data.options}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            onChange={formik.handleChange}
                            int={data.int}
                            disabled={data.disabled}
                        />
                    </>
                ) : data.type === 'mask' ? (
                    <>
                        <CustomMaskInput
                            id={data.id}
                            maskValue={data.maskValue}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            disabled={data.disabled}
                            shrink={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                        ? true
                                        : false
                                    : formik.values[data.name]
                                    ? true
                                    : false
                            }
                            onBlur={formik.handleBlur}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            variant={data.variant}
                            size={data.size}
                            startAdornment={data.startAdornment}
                            endAdornment={data.endAdornment}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            inputStyle={data.inputStyle}
                            upperLabel={data.upperLabel}
                            tooltiptitle={data.tooltiptitle}
                        />
                    </>
                ) : data.type === 'multipleArrayValue' ? (
                    <>
                        <MultipleArray
                            data={data}
                            formik={formik}
                            id={data.id}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                        />
                    </>
                ) : data.type === 'hiddenMask' ? (
                    <>
                        <CustomMaskInputWithHidden
                            id={data.id}
                            maskValue={data.maskValue}
                            noVisiblity={data.noVisiblity}
                            value={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={data.filter ? `filters.${data.name}.value` : data.name}
                            disabled={data.disabled}
                            shrink={
                                data.filter
                                    ? formik.values.filters[data.name].value
                                        ? true
                                        : false
                                    : formik.values[data.name]
                                    ? true
                                    : false
                            }
                            onBlur={formik.handleBlur}
                            onChange={data.onChange ? data.onChange : formik.handleChange}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type}
                            variant={data.variant}
                            size={data.size}
                            startAdornment={data.startAdornment}
                            endAdornment={data.endAdornment}
                            customEndAdornmentOn={data.customEndAdornmentOn}
                            customEndAdornmentOff={data.customEndAdornmentOff}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            inputStyle={data.inputStyle}
                            upperLabel={data.upperLabel}
                            tooltiptitle={data.tooltiptitle}
                            disableHoverListener={data.disableHoverListener}
                            modal={data.modalPP ? data.modalPP : false}
                            last4Masked={data?.last4Masked ? true : false}
                        />
                    </>
                ) : data.type === 'otpInput' ? (
                    <>
                        <OtpInput
                            value={data.value ? data.value : formik.values[data.name]}
                            onChange={(val) => {
                                formik.setFieldValue(data.name, val)
                            }}
                            numInputs={data.inputCount ? data.inputCount : 6}
                            separator={data.separator ? data.separator : <span>-</span>}
                            containerStyle="otpInput"
                            isDisabled={data.isDisabled ? data.isDisabled : false}
                            isInputSecure={data.isInputSecure ? data.isInputSecure : false}
                            shouldAutoFocus={data.shouldAutoFocus ? data.shouldAutoFocus : false}
                            placeholder={data.placeholder ? data.placeholder : ''}
                            isInputNum={true}
                        />
                    </>
                ) : data.type === 'captcha' ? (
                    <>
                        <GoogleCaptch
                            data={data}
                            formik={formik}
                            id={data.id}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                        />
                    </>
                ) : (
                    <>
                        <CustomInput
                            id={data.id}
                            value={
                                data.filter || data.table_search
                                    ? formik.values.filters[data.name].value
                                    : formik.values[data.name]
                            }
                            autoFocus={data.autoFocus}
                            name={
                                data.filter || data.table_search
                                    ? `filters.${data.name}.value`
                                    : data.name
                            }
                            disabled={data.disabled}
                            shrink={
                                data.filter || data.table_search
                                    ? formik.values.filters[data.name].value
                                        ? true
                                        : false
                                    : formik.values[data.name]
                                    ? true
                                    : false
                            }
                            onBlur={
                                typeof data.onBlur !== 'undefined' ? data.onBlur : formik.handleBlur
                            }
                            onChange={(val) => {
                                data.type === 'numbercomma'
                                    ? formik.setFieldValue(
                                          data.name,
                                          currencyFormatWithoutCurrency(val.target.value),
                                      )
                                    : data.onChange
                                    ? data.onChange(val)
                                    : data.filter || data.table_search
                                    ? formik.setFieldValue(
                                          `filters.${data.name}.value`,
                                          val.target.value,
                                      )
                                    : formik.setFieldValue(data.name, val.target.value)
                            }}
                            label={data.label}
                            placeholder={data.placeholder}
                            type={data.type === 'numbercomma' ? 'text' : data.type}
                            variant={data.variant}
                            size={data.size}
                            startAdornment={data.startAdornment}
                            endAdornment={data.endAdornment}
                            error={errorCheck(data, formik)}
                            helperText={
                                helperText(data, formik)
                                    ? helperText(data, formik)
                                    : data.helperText
                            }
                            inputStyle={data.inputStyle}
                            upperLabel={data.upperLabel}
                            tooltiptitle={data.tooltiptitle}
                            disableHoverListener={data.disableHoverListener}
                            onWheel={data.onWheel}
                        />
                    </>
                )}
            </div>
        </>
    ))
    return data
}

export const handleRedirectInternal = (history, path) => {
    console.log(history, 'iiiiiiii', path)
    history.push(`/${path}`)
    window.scrollTo(0, 0)
}

export const dateFormatFront = (data) => {
    return moment(data).isValid()
        ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')}`
        : '-'
}

export const dateFormatFrontDay = (data) => {
    return `${moment(data).format('MMM Do YYYY')}`
}

export const dateFormatFrontTime = (data) => {
    return `${moment(data).format('h:mm a')}`
}

export const checkProductOpen = (date_added, user_role) => {
    let startDate = new Date(serverTime)
    let addDate = new Date(date_added)
    let milliSeconds = 0
    // if (parseInt(user_role) !== 3) {
    //   milliSeconds = 1 * 3600000;
    // } else {
    // }
    let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
    let newaddDate = new Date(incrementedTime)
    console.log('checkProductOpen', newaddDate, startDate)
    if (newaddDate > startDate) {
        return false
        // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
        // let timerTime = incrementedTime - startDate.getTime();
        // if (timerTime <= 0) {
        //   return true;
        // } else {
        //   return false;
        // }
    } else {
        return true
    }
}

export const maskEmailFront = (data) => {
    data = data.toString()
    var first4 = data.substring(0, 4)
    var last5 = data.substring(data.length - 5)
    var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*')
    return first4 + mask + last5
}

export const capitalize = (data) => {
    let dataReturn = '-'
    if (data) {
        data = data.replace(/_/g, ' ').toLowerCase().split(' ')
        // data.split(' ')
        if (data instanceof Array) {
            dataReturn = data
                .map((word) => {
                    return word[0].toUpperCase() + word.substring(1)
                })
                .join(' ')
        } else {
            dataReturn = data.charAt(0).toUpperCase() + data.slice(1)
        }
    }
    return dataReturn
}

export const currencyFormat = (data, locale = true) => {
    let dataReturn = '-'
    if (data) {
        if (locale) {
            dataReturn = `US $${parseFloat(data).toLocaleString('en-US')}`
        } else {
            dataReturn = `US $${parseFloat(data)}`
        }
    }
    return dataReturn
}

export const currencyFormatWithoutCurrency = (data, locale = true) => {
    // Remove non-numeric characters and convert to a number
    const numericValue = parseFloat(data.toString().replace(/[^0-9]/g, ''))

    // Check if numericValue is a valid number
    if (isNaN(numericValue)) {
        return ''
    }

    // Format as currency with commas and 2 decimal places
    const formattedValue = numericValue.toLocaleString(
        global.currencyFormate ? global.currencyFormate : 'en-US',
    )

    return formattedValue
}

export const dateFormatFunction = (data) => {
    let dataReturn = '-'
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(
                global.dateFormat ? global.dateFormat : dateFormat,
            )}`
        }
    }
    return dataReturn
}
export const dateFormatMonthDate = (data) => {
    return `${moment(data).format('MMM Do')}`
}

export const dateTimeFormatFunction = (data) => {
    let dataReturn = '-'
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateTimeFormat)}`
        }
    }
    return dataReturn
}
export const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const DirectAPICAll = (method, url, data, headertype, baseurl) => {
    return new Promise(async (resolve, reject) => {
        try {
            let type = ''
            if (headertype && headertype === 'formdata') {
                type = 'multipart/form-data'
            } else {
                type = 'application/json'
            }
            const config = {
                headers: {
                    'content-type': type,
                },
            }

            if (method === 'get') {
                let res = await axios.get(`${url}`)
                resolve(res)
            } else if (method === 'post') {
                if (data) {
                    let res = await axios.post(`${url}`, data, config)
                    resolve(res)
                } else {
                    let res = await axios.post(`${url}`, config)
                    resolve(res)
                }
            }
        } catch (err) {
            console.log('responsode error from api', err)
            resolve(err)
        }
    })
}

export const useCustomMediaQuery = (queryString) => {
    const [isMatch, setIsMatch] = useState(false)
    const mqChange = (mq) => {
        setIsMatch(mq.matches)
    }

    useEffect(() => {
        const mq = window.matchMedia(queryString)
        mq.addListener(mqChange)
        mqChange(mq)

        return () => {
            mq.removeListener(mqChange)
        }
    }, [queryString])

    return isMatch
}

export const IndianCurrencyInWords = (inputNumber) => {
    let str = new String(inputNumber)
    let splt = str.split('')
    let rev = splt.reverse()
    let once = [
        'Zero',
        ' One',
        ' Two',
        ' Three',
        ' Four',
        ' Five',
        ' Six',
        ' Seven',
        ' Eight',
        ' Nine',
    ]
    let twos = [
        'Ten',
        ' Eleven',
        ' Twelve',
        ' Thirteen',
        ' Fourteen',
        ' Fifteen',
        ' Sixteen',
        ' Seventeen',
        ' Eighteen',
        ' Nineteen',
    ]
    let tens = [
        '',
        'Ten',
        ' Twenty',
        ' Thirty',
        ' Forty',
        ' Fifty',
        ' Sixty',
        ' Seventy',
        ' Eighty',
        ' Ninety',
    ]

    let numLength = rev.length
    let word = new Array()
    let j = 0

    for (var i = 0; i < numLength; i++) {
        switch (i) {
            case 0:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = '' + once[rev[i]]
                }
                word[j] = word[j]
                break

            case 1:
                aboveTens()
                break

            case 2:
                if (rev[i] == 0) {
                    word[j] = ''
                } else if (rev[i - 1] == 0 || rev[i - 2] == 0) {
                    word[j] = once[rev[i]] + ' Hundred '
                } else {
                    word[j] = once[rev[i]] + ' Hundred and'
                }
                break

            case 3:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = once[rev[i]]
                }
                if (rev[i + 1] != 0 || rev[i] > 0) {
                    word[j] = word[j] + ' Thousand '
                }
                break

            case 4:
                aboveTens()
                break

            case 5:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = once[rev[i]]
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + ' Lakh '
                }

                break

            case 6:
                aboveTens()
                break

            case 7:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = once[rev[i]]
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + ' Crore '
                }
                break

            case 8:
                aboveTens()
                break

            default:
                break
        }
        j++
    }

    function aboveTens() {
        if (rev[i] == 0) {
            word[j] = ''
        } else if (rev[i] == 1) {
            word[j] = twos[rev[i - 1]]
        } else {
            word[j] = tens[rev[i]]
        }
    }

    word.reverse()
    let finalOutput = ''
    for (i = 0; i < numLength; i++) {
        finalOutput = finalOutput + word[i]
    }
    return finalOutput
}

const ones = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
]

const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']

const convertTwoDigitsToWords = (num) => {
    if (num < 20) return ones[num]
    const tenPlace = Math.floor(num / 10)
    const unitPlace = num % 10
    return (tens[tenPlace] + (unitPlace ? ' ' + ones[unitPlace] : '')).trim()
}

const convertThreeDigitsToWords = (num) => {
    const hundreds = Math.floor(num / 100)
    const remainder = num % 100

    let words = ''
    if (hundreds) {
        words += ones[hundreds] + ' Hundred'
    }
    if (remainder) {
        words += (words ? ' ' : '') + convertTwoDigitsToWords(remainder)
    }

    return words.trim()
}

const convertLargeNumberToWords = (num) => {
    if (num === 0) return 'Zero'

    let words = ''

    const crore = Math.floor(num / 10000000)
    if (crore > 0) {
        words += convertThreeDigitsToWords(crore) + ' Crore '
        num %= 10000000
    }

    const lakh = Math.floor(num / 100000)
    if (lakh > 0) {
        words += convertThreeDigitsToWords(lakh) + ' Lakh '
        num %= 100000
    }

    const thousand = Math.floor(num / 1000)
    if (thousand > 0) {
        words += convertThreeDigitsToWords(thousand) + ' Thousand '
        num %= 1000
    }

    if (num > 0) {
        words += convertThreeDigitsToWords(num)
    }

    return words.trim()
}

export const IndianCurrencyInWordsWithPaisa = (inputNumber) => {
    if (
        inputNumber === undefined ||
        isNaN(inputNumber) ||
        !Number.isFinite(inputNumber) ||
        inputNumber < 0
    ) {
        return ''
    }

    inputNumber = Math.round(inputNumber * 100) / 100
    const [integerPart, decimalPart] = inputNumber.toString().split('.')

    let integerWords = convertLargeNumberToWords(parseInt(integerPart))
    let decimalWords = decimalPart
        ? convertTwoDigitsToWords(parseInt(decimalPart.padEnd(2, '0'))) + ' Paise'
        : ''

    let finalOutput = integerWords + ' Rupees'
    if (decimalWords) {
        finalOutput += ' and ' + decimalWords
    }

    return finalOutput.trim() || 'Zero'
}

export const removeHTMLTags = (data) => {
    let tempEle = document.createElement('span')
    tempEle.innerHTML = data
    let convertedText = tempEle.textContent || tempEle.innerText || ''
    return convertedText
}

export const isValidHttpUrl = (string) => {
    let url

    try {
        url = new URL(string)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isValidURL = (url) => {
    var urlRegex = new RegExp(
        '^(https?:\\/\\/)?' + // Protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$',
        'i',
    )

    return urlRegex.test(url)
}

export const formatPhone = (phone, removeCountryCode = false) => {
    const countryCode = '+1'
    console.log('i am phone start ', phone)
    const removeCharacter = ['+1', '1', '+', '', 0, '0']
    if (removeCountryCode && removeCharacter.includes(phone)) {
        phone = ''
    } else if (removeCountryCode && typeof phone === 'string' && phone?.length) {
        const regex = /\d+/g
        phone = phone.replace(/^\+?1\s?/, '')
        phone = phone.replace(/^\+/, '')
        const numbersArray = phone?.match(regex)
        const numbersString = numbersArray?.join('')
        phone = parseInt(numbersString, 10)
    } else {
        if (typeof phone === 'string' && !phone?.startsWith('+1')) {
            phone = `${countryCode}${phone}`
        } else {
            phone = `${countryCode}${phone}`
        }
    }
    console.log('i am phone return ', phone)
    return phone
}

export const formatDate = (inputDate, inputDateFormat = 'yyyy-MM-dd', checkFutureDate) => {
    let parsedDate = ''
    if (inputDate.includes('T')) {
        parsedDate = parseISO(inputDate)
    } else {
        parsedDate = parse(inputDate, 'MM-dd-yyyy', new Date())
    }

    if (checkFutureDate && !isAfter(parsedDate, new Date())) {
        return ''
    }
    // console.log('i am date format ', inputDate, inputDateFormat, parsedDate)
    const formattedDate = format(parsedDate, inputDateFormat)
    // console.log('i am date format 2 ', inputDate, inputDateFormat, parsedDate, formattedDate)
    return formattedDate
}

export const detectFileType = (url) => {
    // Extract the file extension from the URL
    const extension = url.split('.').pop().toLowerCase()

    // Define arrays of common image and video extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp']
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm', 'mkv', 'm4v']

    // Check if the extension matches an image or video
    if (imageExtensions.includes(extension)) {
        return 'image'
    } else if (videoExtensions.includes(extension)) {
        return 'video'
    } else {
        return 'unknown'
    }
}
