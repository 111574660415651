import React from 'react'
import Skeletons from '../../Skeletons'

function SignupSkeleton() {
    return (
        <div className="signupSkeleton">
            <div className="ssFields">
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
                <Skeletons type="input" />
            </div>
            <Skeletons type="actionButton" />
        </div>
    )
}

export default SignupSkeleton
