import React, { Component } from 'react'
import CategoryItems from './CategoryItems'

const CategoryDropdown = ({
    submenus,
    allCategories,
    isVisible,
    depthLevel,
    onCountryClick,
    onRegionClick,
    onSubregionClick,
    anchor,
    onMouseLeave,
}) => {
    depthLevel = depthLevel + 1
    const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : ''
    return (
        <ul
            className={`dropdown ${dropdownClass} ${isVisible ? 'show' : ''}`}
            ref={anchor}
            onMouseLeave={onMouseLeave}
        >
            {submenus
                .filter((cat) => cat.level == depthLevel - 1)
                .map((submenu, index) => (
                    <CategoryItems
                        item={submenu}
                        allCategories={allCategories}
                        key={index}
                        onCountryClick={onCountryClick}
                        onRegionClick={onRegionClick}
                        onSubRegionClick={onSubregionClick}
                        depthLevel={depthLevel}
                    />
                ))}
        </ul>
    )
}

export default CategoryDropdown
