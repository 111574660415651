import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AuthContext from '../../../../product/context/auth/authContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import CustomDialog from '../../organisms/Dialog'
import { handleRedirectInternal } from '../../../common/components'
import { currencyFormat } from '../../../../custom/common/components'
import { mapData } from '../../../../product/common/components'
import CyberSourceCardContext from '../../../../product/context/payment/cybersource/card/cardContext'
import AlertContext from '../../../../product/context/alert/alertContext'

const calculateArrayIncrement = (
    allBidIncrements,
    bidIncrementDefault,
    amount,
    is_percentage = 0,
) => {
    const amounttoCheck = parseInt(amount, 10)
    let tosendAmount = 0
    if (allBidIncrements.length > 0) {
        allBidIncrements.forEach((element) => {
            if (
                parseInt(element.from_amount, 10) <= amounttoCheck &&
                parseInt(element.to_amount, 10) >= amounttoCheck
            ) {
                if (is_percentage) {
                    tosendAmount =
                        element.value_type === 'percentage'
                            ? parseFloat(element.increment)
                            : (parseFloat(element.increment) / amounttoCheck) * 100
                } else {
                    tosendAmount = parseInt(element.increment, 10)
                }
            }
        })

        if (tosendAmount === 0) {
            if (bidIncrementDefault.length > 0) {
                tosendAmount = bidIncrementDefault[0].increment
            }
        }
    } else if (bidIncrementDefault && bidIncrementDefault.length > 0) {
        tosendAmount = bidIncrementDefault[0].increment
    }
    return tosendAmount
}

const Bidding = (props) => {
    const { get_all_card_details, getAllCards } = useContext(CyberSourceCardContext)
    const { setAlert } = useContext(AlertContext)

    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const [nextBid, setNextBid] = useState([])
    let { bidConfirm } = buyerContext
    const { user, isAuthenticated } = authContext
    const [product, setProduct] = useState()
    const history = useHistory()
    const customcommonContext = useContext(CustomCommonContext)

    const { allBidIncrements, bidIncrementDefault } = customcommonContext

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleBidSuccessDialog, setToggleBidSuccessDialog] = useState(false)
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const getCyberSourceCards = () => {
        if (user.cybersource_id) {
            getAllCards({
                customer_id: user.cybersource_id,
                object: 'card',
            })
        }
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (allBidIncrements && props.data.next_bid) {
            let nextBid = parseInt(props.data.next_bid)
            let nextprice = []
            let tempNextBid
            for (var i = 1; i <= 10; i++) {
                // tempNextBid = nextBid + (buyerPremium / 100) * nextBid
                tempNextBid = nextBid
                let incrementPrice = calculateArrayIncrement(
                    allBidIncrements,
                    bidIncrementDefault,
                    nextBid,
                )

                nextprice.push({
                    show: currencyFormat(tempNextBid),
                    value: parseFloat(nextBid),
                })
                nextBid += incrementPrice
            }
            setNextBid(nextprice)
        }
    }, [props.data.next_bid, allBidIncrements])

    useEffect(() => {
        if (product) {
            formik.setFieldValue('id', product.id)
        }
        if (isAuthenticated) getCyberSourceCards()
    }, [product])

    // useEffect(() => {
    //     if (!get_all_card_details || get_all_card_details?.records?.length < 1) {
    //         setAlert('Please add a card to place bid', 'error')
    //         handleRedirectInternal(history, 'account/profile')
    //     }
    // }, [get_all_card_details])

    const validationArray = Yup.object({
        wsprice: Yup.number('Numbers only!')
            .min(
                product ? product.next_bid : 0,
                `Min. Bid ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .required('Select bid amount'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: props.data.id,
            bidding_type: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (!get_all_card_details || get_all_card_details?.records?.length < 1) {
                setAlert('Please add a card to place bid', 'error')
                handleRedirectInternal(history, 'account/profile')
            } else {
                if (toggleDialog) {
                    bidConfirm(values)
                    formik.setTouched({})
                    setToggleDialog(false)
                } else {
                    formik.setSubmitting(false)
                    setToggleDialog(true)
                }
            }
        },
    })

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    // const bidAmount = [
    //     {
    //         // label: t('enter_bid_amount'),
    //         name: 'wsprice',
    //         type: 'number',
    //         placeholder: `${t('min_bid')} ${
    //             product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0)
    //         }`,
    //         class: 'col-12 p-0',
    //         autoFocus: false,
    //         formik: formik,
    //     },
    // ]
    const bidAmount = [
        {
            label: '',
            name: 'wsprice',
            type: 'select',
            placeholder: `Select Bid Amount (Per Lot)`,
            class: 'col-12 p-0',
            options: nextBid,
            upperLabel: true,
            formik: formik,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex justify-content-between align-items-start">
                                <div className="row m-0">{Object.values(mapData(bidAmount))}</div>
                                {console.log('max bid val -->', formik.values.wsprice)}
                                {isAuthenticated ? (
                                    product.bidtopstatus === 'winner' ? (
                                        <SecondaryButton
                                            id="placehardbid_button"
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? t('increase_hard_bid')
                                                    : 'Increase max bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                        />
                                    ) : (
                                        <PrimaryButton
                                            id="placebid_button"
                                            label={
                                                formik.values.bidding_type === 'hard' ? (
                                                    <p className="m-0 d-flex align-items-center">
                                                        <span className="material-icons mr-3">
                                                            gavel
                                                        </span>{' '}
                                                        Submit Bid
                                                    </p>
                                                ) : (
                                                    <p className="m-0 d-flex align-items-center">
                                                        <span className="material-icons mr-3">
                                                            gavel
                                                        </span>{' '}
                                                        Place Bid
                                                    </p>
                                                )
                                            }
                                            type="submit"
                                            btnSize="small"
                                        />
                                    )
                                ) : (
                                    <PrimaryButton
                                        id="logintobid_button"
                                        label="Login to Bid"
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `login?pid=${product.id}`,
                                            )
                                        }
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}
                </>
            ) : null}
            <CustomDialog title={'Confirm Bid'} open={toggleDialog} function={changeDialogStatus}>
                <h5>{'You are about to place a bid'}!</h5>
                <h5>{'Please confirm if you want to continue with this action'}</h5>
                <div className="actionWrapper">
                    <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                    <PrimaryButton
                        onClick={formik.handleSubmit}
                        type="button"
                        disabled={formik.isSubmitting}
                        label={'Submit'}
                        id="confirmbid_button"
                    />
                </div>
            </CustomDialog>
        </>
    )
}

export default Bidding
