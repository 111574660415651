import React from 'react'
import { useEffect } from 'react'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    const pageContent = [
        {
            type: 'bold',
            text: 'Privacy',
        },
        {
            type: 'normal',
            text: 'The Chicago Wine Company is committed to protecting your privacy. We collect information about you in order to efficiently process your orders and create the best possible experience while browsing www.tcwc.com. What we collect and how we use this information is detailed below.',
        },
        {
            type: 'normal',
            text: 'Order Information: We collect your name, email, address and credit card number, all of which is necessary for us to process your order and communicate with regarding order status.',
        },
        {
            type: 'normal',
            text: 'Browsing Data: We collect browsing data on www.tcwc.com to improve web site content, functionality and communications with our customers. Other non-personally identifiable information may be collected as well such as domain, browser type and service provider.',
        },
        {
            type: 'normal',
            text: 'There are links to affiliate and third party web sites on www.tcwc.com. We do not monitor and are not responsible for the policies and content of these sites. We encourage you to familiarize yourself with the policies of these sites prior to sharing personal information.',
        },
        {
            type: 'normal',
            text: 'The Chicago Wine Company does not sell or rent any personal information to outside parties. However, we will, to the extent necessary to provide the services requested by you, provide third parties information for functions such as order fulfillment and payment processing. Also, we will share personal information if required by law.',
        },
        {
            type: 'normal',
            text: 'Any changes to our privacy policy will be posted on our web site so you are always aware of how the information you provide is being used. If you have any further questions or concerns regarding our privacy policy we encourage you to contact us at info@tcwc.com or call 630-594-2972.',
        },
        {
            type: 'bold',
            text: 'Accessibility',
        },
        {
            type: 'normal',
            text: 'The Chicago Wine Company LLC is committed to making our website content accessible and user friendly to everyone. If you are having difficulty viewing or navigating the content on this website, or notice any content, feature, or functionality that you believe is not fully accessible to people with disabilities, please call our Customer Service team at 1-630-594-2972, or email our team at info@tcwc.com, include "Disabled Access" in the subject line, and provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement. We take your feedback seriously and will consider it as we evaluate ways to accommodate all of our customers and our overall accessibility policies. Additionally, while we do not control such vendors, we strongly encourage vendors of third-party digital content to provide content that is accessible and user friendly.',
        },
    ]

    const populateSubContent = (content) => {
        let returnResultSet = []

        let pTag = <></>
        if (content) {
            // Loop through content
            content.forEach((content_piece, idx) => {
                // Create p Tag
                pTag = <p key={idx}>{content_piece}</p>

                // Push p Tag to returnResultSet
                returnResultSet.push(pTag)
            })
        }

        return returnResultSet
    }

    const populateSellingWineText = (pageContentObject, key) => {
        let returnText = ''
        const boldParagraph = (
            <p key={key}>
                <strong>{pageContentObject.text}</strong>
            </p>
        )
        const normalParagraph = <p key={key}>{pageContentObject.text}</p>
        const subBoldParagraph = (
            <div key={key} className="sub-content">
                {boldParagraph}
                {populateSubContent(pageContentObject.pieces).map((content, idx) => {
                    return content
                })}
            </div>
        )
        const subNormalParagraph = (
            <div key={key} className="sub-content">
                {populateSubContent(pageContentObject.pieces).map((content, idx) => {
                    return content
                })}
            </div>
        )

        switch (pageContentObject.type) {
            case 'bold':
                returnText = boldParagraph
                break
            case 'normal':
                if (pageContentObject.pieces) {
                    // pageContent contains "pieces"
                    returnText = (
                        <>
                            {normalParagraph}
                            {subNormalParagraph}
                        </>
                    )
                } else {
                    // pageContentObject does NOT contain "pieces"
                    returnText = normalParagraph
                }

                break
            case 'sub-bold':
                returnText = subBoldParagraph
                break
            default:
                returnText = <p></p>
        }

        return returnText
    }

    return (
        <section className="postbanner">
            <img src="/assets/images/banner1.png" alt="postbanner img" />
            <div className="postForm ck-content">
                <h4 className="postTit">Privacy & Accessibility</h4>
                {pageContent.map((content, idx) => {
                    return populateSellingWineText(content, idx)
                })}
            </div>
        </section>
    )
}
export default PrivacyPolicy
