import {
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_ALL_CUSTOMER_ORDERS,
    GET_ALL_VENDOR_SOLD,
    GET_ALL_VENDOR_SELLING,
    GET_ALL_CUSTOMER_UNPAID,
    GET_ALL_CUSTOMER_PAID,
} from './netSuiteTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_CUSTOMER_ORDERS:
            return {
                ...state,
                customer_orders: action.payload,
            }
        case GET_ALL_VENDOR_SOLD:
            return {
                ...state,
                vendor_sold: action.payload,
            }
        case GET_ALL_VENDOR_SELLING:
            return {
                ...state,
                vendor_selling: action.payload,
            }
        case GET_ALL_CUSTOMER_UNPAID:
            return {
                ...state,
                customer_unpaid: action.payload,
            }
        case GET_ALL_CUSTOMER_PAID:
            return {
                ...state,
                customer_paid: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
