import React from 'react'
import { useEffect } from 'react'
import './ContactUs.css'

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    const pageContent = [
        {
            type: 'bold',
            text: 'Our offices, tasting room and cellar are located at:',
        },
        {
            type: 'normal',
            text: 'The Chicago Wine Company',
        },
        {
            type: 'normal',
            text: '835 N. Central',
        },
        {
            type: 'normal',
            text: 'Wood Dale, IL 60191',
        },
        {
            type: 'normal',
            text: 'U.S.A.',
        },
        {
            type: 'normal',
            text: 'Number: (630) 594-2972',
        },
        {
            type: 'normal',
            text: 'Fax Number: (630) 594-2978',
        },
        {
            type: 'normal',
            text: 'General Inquiries:info@tcwc.com',
        },
        {
            type: 'normal',
            text: 'Selling Wine: selling@tcwc.com',
        },
        {
            type: 'normal',
            text: 'Hours of Operation:',
        },
        {
            type: 'normal',
            text: 'Monday-Friday: 9:30 am - 5:30 pm',
        },
    ]

    const populateSubContent = (content) => {
        let returnResultSet = []

        let pTag = <></>
        if (content) {
            // Loop through content
            content.forEach((content_piece, idx) => {
                // Create p Tag
                pTag = <p key={idx}>{content_piece}</p>

                // Push p Tag to returnResultSet
                returnResultSet.push(pTag)
            })
        }

        return returnResultSet
    }

    const populateSellingWineText = (pageContentObject, key) => {
        let returnText = ''
        const boldParagraph = (
            <p key={key}>
                <strong>{pageContentObject.text}</strong>
            </p>
        )
        const normalParagraph = <p key={key}>{pageContentObject.text}</p>
        const subBoldParagraph = (
            <div key={key} className="sub-content">
                {boldParagraph}
                {populateSubContent(pageContentObject.pieces).map((content, idx) => {
                    return content
                })}
            </div>
        )
        const subNormalParagraph = (
            <div key={key} className="sub-content">
                {populateSubContent(pageContentObject.pieces).map((content, idx) => {
                    return content
                })}
            </div>
        )

        switch (pageContentObject.type) {
            case 'bold':
                returnText = boldParagraph
                break
            case 'normal':
                if (pageContentObject.pieces) {
                    // pageContent contains "pieces"
                    returnText = (
                        <>
                            {normalParagraph}
                            {subNormalParagraph}
                        </>
                    )
                } else {
                    // pageContentObject does NOT contain "pieces"
                    returnText = normalParagraph
                }

                break
            case 'sub-bold':
                returnText = subBoldParagraph
                break
            default:
                returnText = <p></p>
        }

        return returnText
    }

    return (
        <section className="postbanner">
            <img src="/assets/images/banner1.png" alt="postbanner img" />
            <div className="postForm ck-content">
                <h4 className="postTit">Contact Us</h4>
                {pageContent.map((content, idx) => {
                    return populateSellingWineText(content, idx)
                })}
            </div>
        </section>
    )
}
export default ContactUs
