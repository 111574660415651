export const USER_LOADED = 'USER_LOADED'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const NORTH_CAPITAL_BANK_DETAILS = 'NORTH_CAPITAL_BANK_DETAILS'
export const NORTH_CAPITAL_CARD_DETAILS = 'NORTH_CAPITAL_CARD_DETAILS'
export const RESPONSE_STBIDS = 'RESPONSE_STBIDS'
export const RESPONSE_ASSET_TOTAL = 'RESPONSE_ASSET_TOTAL'
export const PARTY_DOCUMENTS = 'PARTY_DOCUMENTS'
export const PARTY_OWNER_DOCUMENTS = 'PARTY_OWNER_DOCUMENTS'
export const NORTH_CAPITAL_CARD_LINK = 'NORTH_CAPITAL_CARD_LINK'
