import React, { useState, useContext, useEffect } from 'react'
import AuthContext from '../../../../product/context/auth/authContext'
import CartContext from '../../../../product/context/cart/cartContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import UserContext from '../../../../product/context/user/userContext'
import ProductContext from '../../../../product/context/product/productContext'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import PayContext from '../../../../product/context/payment/payContext'
import CreditContext from '../../../../product/context/credit/creditContext'
import OfflineContext from '../../../../product/context/offline/offlineContext'
import CyberSourceCardContext from '../../../../product/context/payment/cybersource/card/cardContext'
import VersaPayCardContext from '../../../../product/context/payment/versapay/card/cardContext'

import CyberSourceCustomerContext from '../../../../product/context/payment/cybersource/customer/customerContext'
import CustAuthContext from '../../../context/auth/authContext'

const HeadAlert = () => {
    const authContext = useContext(AuthContext)
    const cutsAuthContext = useContext(CustAuthContext)
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const creditContext = useContext(CreditContext)
    const offlineContext = useContext(OfflineContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const buynowContext = useContext(BuynowContext)
    const cyberSourceCustomerContext = useContext(CyberSourceCustomerContext)
    const cyberSourceCardContext = useContext(CyberSourceCardContext)
    const versaPayCardContext = useContext(VersaPayCardContext)

    const { setAlert } = alertContext
    const { responseStatus: responseStatusAuth, clearResponse: clearResponseAuth } = authContext
    const { responseStatus: responseStatusAuthCust, clearResponse: clearResponseAuthCust } =
        cutsAuthContext
    const {
        responseStatus: responseStatusCyberSourceCustomer,
        clearResponse: clearResponseCyberSourceCustomer,
    } = cyberSourceCustomerContext

    const {
        responseStatus: responseStatusCyberSourceCard,
        clearResponse: clearResponseCyberSourceCard,
    } = cyberSourceCardContext

    const { responseStatus: responseStatusVersaPayCard, clearResponse: clearResponseVersaPayCard } =
        versaPayCardContext

    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext
    const { responseStatus: responseStatusPayment, clearResponse: clearResponsePayment } =
        payContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const { responseStatus: responseStatusCredit, clearResponse: clearResponseCredit } =
        creditContext

    const { responseStatus: responseStatusOffline, clearResponse: clearResponseOffline } =
        offlineContext

    const { responseStatus: responseStatusAutoPlt, clearResponse: clearResponseAutoPlt } =
        autopltContext

    const { responseStatus: responseStatusBuyNow, clearResponse: clearResponseBuyNow } =
        buynowContext

    useEffect(() => {
        if (responseStatusUser) {
            if (!responseStatusUser.noAlert) {
                setAlert(responseStatusUser.message, responseStatusUser.status)
            }

            // if (responseStatusUser.message === 'Netsuite api failed!') {
            //     setAlert(
            //         'Please supply all fields: First, Last, Phone...',
            //         responseStatusUser.status,
            //     )
            // } else if (!responseStatusUser.noAlert) {
            //     setAlert(responseStatusUser.message, responseStatusUser.status)
            // }

            clearResponseUser()
        }
        if (responseStatusProduct) {
            if (!responseStatusProduct.noAlert) {
                setAlert(responseStatusProduct.message, responseStatusProduct.status)
            }
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            if (!responseStatusAutoPlt.noAlert) {
                setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            }
            clearResponseAutoPlt()
        }
        if (responseStatusAuth) {
            if (!responseStatusAuth.noAlert) {
                setAlert(responseStatusAuth.message, responseStatusAuth.status)
            }
            clearResponseAuth()
        }
        if (responseStatusAuthCust) {
            if (!responseStatusAuthCust.noAlert) {
                setAlert(responseStatusAuthCust.message, responseStatusAuthCust.status)
            }
            clearResponseAuthCust()
        }
        if (responseStatusBuyNow) {
            if (!responseStatusBuyNow.noAlert) {
                setAlert(responseStatusBuyNow.message, responseStatusBuyNow.status)
            }
            clearResponseBuyNow()
        }
        if (responseStatusPayment) {
            if (!responseStatusPayment.noAlert) {
                setAlert(responseStatusPayment.message, responseStatusPayment.status)
            }
            clearResponsePayment()
        }
        if (responseStatusCart) {
            if (!responseStatusCart.noAlert) {
                setAlert(responseStatusCart.message, responseStatusCart.status)
            }
            clearResponseCart()
        }
        if (responseStatusCredit) {
            if (!responseStatusCredit.noAlert) {
                setAlert(responseStatusCredit.message, responseStatusCredit.status)
            }
            clearResponseCredit()
        }
        if (responseStatusOffline) {
            if (!responseStatusOffline.noAlert) {
                setAlert(responseStatusOffline.message, responseStatusOffline.status)
            }
            clearResponseOffline()
        }
        if (responseStatusCyberSourceCustomer) {
            if (!responseStatusCyberSourceCustomer.noAlert) {
                setAlert(
                    responseStatusCyberSourceCustomer.message,
                    responseStatusCyberSourceCustomer.status,
                )
            }
            clearResponseCyberSourceCustomer()
        }
        if (responseStatusCyberSourceCard) {
            if (!responseStatusCyberSourceCard.noAlert) {
                setAlert(
                    responseStatusCyberSourceCard.message,
                    responseStatusCyberSourceCard.status,
                )
            }
            clearResponseCyberSourceCard()
        }
        if (responseStatusVersaPayCard) {
            if (!responseStatusVersaPayCard.noAlert) {
                setAlert(responseStatusVersaPayCard.message, responseStatusVersaPayCard.status)
            }
            clearResponseVersaPayCard()
        }
    }, [
        responseStatusUser,
        responseStatusProduct,
        responseStatusAutoPlt,
        responseStatusAuth,
        responseStatusBuyNow,
        responseStatusPayment,
        responseStatusAuthCust,
        responseStatusCart,
        responseStatusCredit,
        responseStatusOffline,
        responseStatusCyberSourceCustomer,
        responseStatusCyberSourceCard,
        responseStatusVersaPayCard,
    ])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    return <></>
}

export default HeadAlert
